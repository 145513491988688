import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useLanguage from 'utils/hooks/useLanguage';

import { useDispatch } from 'react-redux';
import { clientNotificationSelector } from 'store/Client/ClientNotification';

import { fetchUser } from 'store/UserSession/features';

import TokenService from 'config/token.service';

import { VideoInstructionsModal } from 'components/shared/modal/VideoInstructionsModal';
import { DownloadMenu } from 'components/shared/DropDown/DownloadMenu';
import { useSelector } from 'react-redux';

import axiosPrivate from 'config/axiosPrivate';

const Languages = [
  { text: 'en', img: 'Images/img_flag/GB_flag.png' },
  { text: 'fr', img: 'Images/img_flag/FR_flag.png' },
  { text: 'de', img: 'Images/img_flag/DE_flag.png' },
  { text: 'es', img: 'Images/img_flag/ES_flag.png' },
  { text: 'it', img: 'Images/img_flag/IT_flag.png' },
  { text: 'da', img: 'Images/img_flag/DA_flag.png' },
  { text: 'fi', img: 'Images/img_flag/FI_flag.png' },
  { text: 'nb', img: 'Images/img_flag/NB_flag.png' },
  { text: 'nl', img: 'Images/img_flag/NL_flag.png' },
  { text: 'pl', img: 'Images/img_flag/PL_flag.png' },
  { text: 'pt', img: 'Images/img_flag/PT_flag.png' },
  { text: 'ro', img: 'Images/img_flag/RO_flag.png' },
  { text: 'sv', img: 'Images/img_flag/SV_flag.png' },
  { text: 'el', img: 'Images/img_flag/GK_flag.png' },
  { text: 'sk', img: 'Images/img_flag/SK_flag.png' },
  { text: 'cs', img: 'Images/img_flag/CS_flag.png' },
];

export function NavMenuDetails({ user, paths, currentPage }) {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const { newNotifications } = useSelector(clientNotificationSelector);
  const { language, changeLanguage } = useLanguage();
  const [showInstructions, setShowInstructions] = useState(false);
  // const [selectedLanguage, setSelectedLanguage] = useState(null)

  const handleChangeLanguage = (language) => {
    changeLanguage(language);
    axiosPrivate
      .put(`/user/${language}/updateLanguage`)
      .then(() => {})
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <header className="border-bottom fixed-top header-fix bg-light-wh bg-img">
      <div
        className="container-fluid d-grid gap-3 align-items-center"
        style={{ gridTemplateColumns: '1fr 2fr' }}
      >
        <div className="dropdown">
          {(user && user.role === 'CLIENT') || user.role === 'CONSULTANT' ? (
            <Link tag={Link} to={paths.mainMenu}>
              <img
                className="logo-m"
                src="Images/logo-typix.png"
                alt="logo-typix"
              />
            </Link>
          ) : null}
        </div>
        <div className="d-flex align-items-center ">
          <form className="box-menu-list w-100 text-end ">
            <DownloadMenu user={user} />

            {user && user.role === 'ADMIN' ? (
              <div className="d-flex align-items-center ">
                <Link to={paths.addClient}>
                  <div className="btn-menu-icon">
                    <div
                      className={`${
                        currentPage === paths.addClient
                          ? 'btn-menu-list active'
                          : 'btn-menu-list'
                      }`}
                    >
                      <span>{t('Ajouter un client')}</span>
                    </div>
                  </div>
                </Link>

                <Link to={paths.newOrder}>
                  <div className="btn-menu-icon">
                    <div
                      className={`${
                        currentPage === paths.newOrder
                          ? 'btn-menu-list active'
                          : 'btn-menu-list'
                      }`}
                    >
                      <span>{t('Nouvelle commande')}</span>
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              <div>
                {user && user.role === 'CONSULTANT' ? null : (
                  <div className="btn-menu-icon">
                    <div
                      className="box-icon-menu bt-icon-video"
                      onClick={() => {
                        setShowInstructions(true);
                      }}
                    ></div>
                  </div>
                )}
              </div>
            )}

            {user && user.role !== 'CLIENT' ? null : (
              <Link to={paths.notification}>
                <div className="btn-menu-icon icon-notification">
                  <div className="box-icon-menu bt-icon-alert"></div>
                  {newNotifications.length > 0 ? (
                    <span className="icon-notification-badge-nav">
                      {newNotifications.length}
                    </span>
                  ) : null}
                </div>
              </Link>
            )}

            <div className="btn-menu-icon">
              <div
                className={`${
                  currentPage === paths.userProfile
                    ? 'box-icon-menu bt-icon-user active'
                    : 'box-icon-menu bt-icon-user'
                }`}
                id="dropdownMenu2"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              />
              <ul
                className="dropdown-menu menu-list-nav menu-list-sm"
                aria-labelledby="dropdownMenu2"
              >
                <li className="first-child">
                  <Link to={paths.userProfile}>
                    <button className="dropdown-item" type="button">
                      {t('Mon profil')}
                    </button>
                  </Link>
                </li>
                <li className="last-child">
                  <button
                    onClick={() => {
                      TokenService.removeUser();
                      dispatch(fetchUser(null));
                    }}
                    className="dropdown-item"
                    type="button"
                  >
                    {t('Déconnexion')}
                  </button>
                </li>
              </ul>
            </div>

            <div className="btn-menu-icon">
              <button
                className="btn dropdown-toggle d-flex flex-row align-items-baseline"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <span>
                  <div className="flag-img-container-country">
                    <img
                      src={
                        Languages.find(
                          (languageAttempt) => languageAttempt.text === language
                        ).img
                      }
                      alt=""
                    />
                  </div>
                </span>
              </button>
              <ul
                className="dropdown-menu menu-list-nav menu-list-lng"
                aria-labelledby="dropdownMenu2"
              >
                {Languages.map((language, index) => (
                  <li
                    key={index}
                    onClick={() => handleChangeLanguage(language.text)}
                  >
                    <button
                      className="dropdown-item d-flex justify-content-center"
                      type="button"
                    >
                      <div className="flag-img-container">
                        <img src={language.img} alt="language" />
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </form>
        </div>
      </div>
      <VideoInstructionsModal
        showInstruction={showInstructions}
        setShowInstruction={() => setShowInstructions(false)}
        user={user}
      />
    </header>
  );
}
