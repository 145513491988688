export const finnishTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Komentorivit on päivitetty onnistuneesti!',
  'La traduction a été enregistrée avec succès':
    'Käännös tallennettiin onnistuneesti',
  'Texte + Traduction enregistré avec succès':
    'Teksti + Käännös tallennettiin onnistuneesti',
  'Nombre de mots': 'Sanojen määrä',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Asiakkaan etunimi/sukunimi',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Päivämäärän on oltava myöhemmin kuin tänään.',
  'Contenu rédigé': 'Kirjoitettu sisältö',
  'Nom/prénom': 'Sukunimi / etunimi',
  'Mot de passe incorrect': 'Virheellinen salasana',
  Institutions: 'Toimielimet',
  'Veuillez donner un descriptif au document téléchargé':
    'Anna kuvaus ladatusta asiakirjasta',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Seuraava',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"': 'Erottele avainsanat "enterillä".',
  Allemand: 'Saksan',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'sähköpostiisi on lähetetty salasanan palautuslinkin sisältävä sähköpostiviesti.',
  'Chef edito': 'Päätoimittaja',
  Croate: 'Kroatian',
  'Nb de textes': 'Tekstien lukumäärä',
  Violet: 'Violetti',
  'Ce champs doit être sup ou égale nombre de texte':
    'Tämän kentän on oltava suurempi tai yhtä suuri kuin tekstin määrä.',
  'a été activé avec succès': 'on onnistuneesti aktivoitu',
  Népalais: 'Nepalilainen',
  'Nombre de FAQ': 'Useiden usein kysyttyjen kysymysten määrä',
  Urdu: 'Urdu',
  'Analyses restantes': 'Jäljellä olevat analyysit',
  Indonésien: 'Indonesialainen',
  Breton: 'Breton',
  Livrés: 'Toimitettu',
  Associations: 'Yhdistykset',
  'Le client': 'Asiakas',
  'Révision demandée': 'Pyydetty tarkistus',
  "Une erreur s'est produite, veuillez réessayer":
    'On tapahtunut virhe, yritä uudelleen',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Luvaton päivitys, koska tekstit on toimitettu.',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Tekstin lisääminen (olemassa olevan sisällön optimointi)',
  Gaélique: 'Gaelic',
  'La ligne de commande pour': 'Komentorivi',
  'Business/Management': 'Liiketoiminta/johtaminen',
  Serbe: 'Serbian',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Ilmoittakaa alla muutospyyntönne ottaen huomioon silitysehdot.',
  'rédacteurs trouvés': 'löytyi kirjailijoita',
  'Kirghiz ; Kirghiz': 'Kirgis; Kirgis',
  Différence: 'Ero',
  'en cours': 'käynnissä',
  'Sami du Nord': 'Pohjoissaame',
  Comparer: 'Vertaa',
  Lituanien: 'Liettuan',
  'Typix - Mon tableau de bord': 'Typix - Oma kojelauta',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Vähintään 8 merkkiä, jossa on oltava iso kirjain, numero tai erikoismerkki ja joka ei saa olla sama kuin nimi.',
  Webmaster: 'Verkkomestari',
  'Veuillez saisir des champs valides': 'Syötä kelvolliset kentät',
  Basque: 'Baskimaan',
  'Exporter .csv': 'Vie .csv',
  'Confirmer le nouveau mot de passe': 'Vahvista uusi salasana',
  "Pas d'options": 'Ei vaihtoehtoja',
  'Banque/Assurance': 'Pankkitoiminta/Vakuutus',
  'Vos demandes': 'Pyyntönne',
  'Coût d\u2019achat': 'Hankintakustannukset',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Teksti poistettiin onnistuneesti',
  'Mes Commandes': 'Tilaukseni',
  Zoulou: 'Zulu',
  Afficher: 'Näytä',
  Texte: 'Teksti',
  Islandais: 'Islantilainen',
  Russe: 'Venäläinen',
  'Tous les textes': 'Kaikki tekstit',
  Czech: 'Tšekki',
  'Comparaison hors ligne': 'Offline-vertailu',
  Téléphonie: 'Puhelinpalvelut',
  'Valider le texte': 'Vahvista teksti',
  'Votre text ne de doit pas dépasser 500 mots':
    'Tekstisi ei saisi ylittää 500 sanaa',
  'Coquilles (double espace, « s »\u2026)':
    'Kuoret (kaksoisvälilyönti, "s"...)',
  'le consultant': 'konsultti',
  Coréen: 'Korean',
  'Nb de Silo': 'Siilojen lukumäärä',
  Validés: 'Validoitu',
  'non attribuées': 'jakamaton',
  Néerlandais: 'Hollantilainen',
  'Voulez-vous vraiment supprimer': 'Haluatko todella poistaa',
  Rundi: 'Rundi',
  Regional: 'Alueellinen',
  'Compte client': 'Asiakastili',
  Global: 'Maailmanlaajuinen',
  'Tous les rôles': 'Kaikki roolit',
  Musique: 'Musiikki',
  Perso: 'Henkilökohtainen',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Näyttää siltä, että teillä ei ole enää analyysiluottoa',
  Bambara: 'Bambara',
  Sortir: 'Ulos!',
  optionel: 'valinnainen',
  'Temps restant': 'Jäljellä oleva aika',
  Ukrainien: 'Ukrainan',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Tarkistuspyyntösi on lähetetty.',
  'Typix - Créer rédacteur': 'Typix - Luo editori',
  "n'existe pas": 'ei ole olemassa',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'ei voida poistaa, koska se on linkitetty verkkosivustoihin',
  'Commandes en cours': 'Käynnissä olevat tilaukset',
  'Textes livrés': 'Toimitetut tekstit',
  Validation: 'Validointi',
  'Non attribuées': 'Ei myönnetty',
  'Voulez-vous vraiment désactiver': 'Haluatko todella poistaa',
  'Catalan ; Valencien': 'katalaani; valencialainen',
  'Nombre de textes restants': 'Jäljellä olevien tekstien määrä',
  'Ne doit pas contenir votre nom': 'Ei saa sisältää nimeäsi',
  Documentation: 'Dokumentaatio',
  Kazakh: 'Kazakstan',
  'Numéro invalide': 'Virheellinen numero',
  'Veuillez saisir un texte ou importer un fichier.':
    'Kirjoita teksti tai tuo tiedosto.',
  'Nb de FAQ': 'Usein kysyttyjen kysymysten määrä',
  Analyser: 'Analysoi',
  Turc: 'Turkkilainen',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Kaikki muut kuin toimeksiannossa mainitut pyynnöt',
  Wallon: 'Vallonian',
  Birmane: 'Burman',
  Irlandais: 'Irlantilainen',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Nollaa salasanasi',
  Cuisine: 'Keittiö',
  'Haïtien ; créole haïtien': 'Haitin kieli; Haitin kreoli',
  'Vous ne pouvez pas supprimer le rédacteur': 'Et voi poistaa editoria',
  Validé: 'Validoitu',
  "cet email n'existe pas": 'tätä sähköpostia ei ole olemassa',
  Historique: 'Historia',
  Confirmer: 'Vahvista',
  'à cocher si le nombre de mots est différent par texte':
    'rastitetaan, jos sanojen määrä vaihtelee tekstikohtaisesti.',
  'Fiche du site web ': 'Verkkosivusto',
  Désactiver: 'Deaktivoi',
  'Travaux/BTP': 'Työt/Rakentaminen',
  'La fiche du website': 'Verkkosivujen tiedot',
  'Si le contenu ne respecte pas les règles du brief':
    'Jos sisältö ei noudata tehtävänantoa koskevia sääntöjä',
  Norvégien: 'Norjalainen',
  '0 rédacteurs': '0 kirjoittajat',
  indisponible: 'ei saatavilla',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Tarkistetut tekstit',
  'Status texte': 'Tekstin tila',
  'textes livrés en attente de validation':
    'toimitetut tekstit odottavat validointia',
  'Typix - Mon Profile': 'Typix - Oma profiili',
  'le client': 'asiakas',
  Romanche: 'Romanche',
  'URL du site': 'Sivuston URL-osoite',
  'nouvelles notifications': 'uudet ilmoitukset',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Malediivit',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Haluatko varmasti tallentaa tekstin',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Lisää alkuteksti, jotta voit tallentaa tekstin luonnoksena.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Sopimus asiakkaan kanssa',
  rédacteurs: 'kirjoittajat',
  'Ce champ est obligatoire': 'Tämä kenttä on pakollinen',
  'Tarif/Mot': 'Hinta/sana',
  Tadjik: 'Tadžikistan',
  'Réf devis': 'Viite lainaus',
  'Rechercher un rédacteur': 'Etsi kirjailija',
  Albanais: 'Albanian',
  Galicien: 'Galician',
  Disponibilité: 'Saatavuus',
  'Les deux mots de passe ne sonts pas identiques':
    'Nämä kaksi salasanaa eivät ole identtisiä',
  Tibétain: 'Tiibetiläinen',
  'Typix - Gestion des utilisateurs': 'Typix - Käyttäjien hallinta',
  'Ossétien ; Ossétique': 'ossetialainen; ossetialainen',
  Tswana: 'Tswana',
  'Ajouter un client': 'Lisää asiakas',
  'Séparez les mots-clés par un " Enter ".': 'Erottele avainsanat Enterillä.',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Teemat',
  'Nb mots/texte': 'Sanojen/tekstin määrä',
  Commerce: 'Kauppa',
  'Voir les commandes': 'Katso tilaukset',
  'Nombre de mots par texte': 'Sanojen määrä tekstiä kohti',
  'Lien drive...': 'Linkki ajaa...',
  'Hollandais ; Flamand': 'hollantilainen; flaamilainen',
  'Mon profil': 'Profiilini',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Tämän kentän on oltava suurempi kuin 0, koska laskutus ei ole todellista.',
  'Le texte a été validé avec succès': 'Teksti on validoitu onnistuneesti',
  Jeux: 'Pelit',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Haluatko todella poistaa komentoriviltä komennon',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, pohjoinen; Ndebele pohjoinen',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'kirkkoslaavin kieli; vanha slaavilainen kieli; kirkkoslaavin kieli; vanha bulgarian kieli; vanha kirkkoslaavin kieli.',
  Swahili: 'Swahili',
  Voyage: 'Matkailu',
  'Nombre de mot à ajouter': 'Lisättävien sanojen määrä',
  'Liste des rédacteurs': 'Luettelo kirjoittajat',
  'le rédacteur': 'toimittaja',
  Kanuri: 'Kanuri',
  rédacteur: 'toimittaja',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'Romanian; Moldovan',
  'Le texte initial de': 'Alkuperäinen teksti',
  'Mail clients': 'Asiakkaan posti',
  'Le contrat de': 'Sopimus',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'vähintään yksi pieni ja yksi iso kirjain, yksi erikoismerkki ja yksi numero.',
  Transport: 'Liikenne',
  'Nombre total de mots (existants et ajoutés) :':
    'Sanojen kokonaismäärä (olemassa olevat ja lisätyt) :',
  'Rechercher par mail, prénom, nom, site...':
    'Haku sähköpostin, etunimen, sukunimen, sivuston...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Lisättävien sanojen määrä (Min/Max) :',
  Compte: 'Tili',
  'Tourisme/Loisirs': 'Matkailu/Vapaa-aika',
  'Nouveau texte': 'Uusi teksti',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grönlantilainen',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Hups...! Semanttisen aukon ikkunassa on yksi tai useampi virhe. Tarkista sen sisältö korjataksesi ne ja lähetä tekstisi uudelleen.',
  'ne peut pas être supprimé pour le moment': 'ei voida poistaa tällä hetkellä',
  'Le texte': 'Teksti',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Käyttäjä on jo olemassa, jolla on sähköpostiosoite',
  Cree: 'Cree',
  Prénom: 'Etunimi',
  'commandes prête': 'tilaukset valmiina',
  Réinitialiser: 'Nollaa',
  'Veuillez fournir une date valide': 'Anna voimassa oleva päivämäärä',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxemburgin kieli; Letzeburgesch',
  Suédois: 'Ruotsalainen',
  'Confirmer le mot de passe': 'Vahvista salasana',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Tämä kenttä on pakollinen, ja siinä voi olla vain numeroita',
  Sciences: 'Tiede',
  Tchétchène: 'Tšetšenia',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'on sivuston ainoa asiakas, muita on lisättävä, jotta se voidaan poistaa.',
  'Le mot de passe de confirmation ne correspond pas':
    'Vahvistussalasana ei täsmää',
  Swati: 'Swati',
  Danois: 'Tanskalainen',
  Sauvegarder: 'Tallenna',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Muuttakaa seuraavat tiedot: otsikko ja/tai avainsana.',
  'importer un document': 'asiakirjan tuominen',
  Maltais: 'Maltan',
  'Nb de mots': 'Sanojen lukumäärä',
  'Les données fournies sont invalides': 'Toimitetut tiedot ovat virheellisiä',
  'Livraison estimée': 'Arvioitu toimitusaika',
  'Grec moderne (1453-)': 'Nykykreikka (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Lisättyjen sanojen kokonaismäärä',
  'le contenu présente des inexactitudes': 'sisältö sisältää epätarkkuuksia',
  Finnois: 'Suomalainen',
  'Ajouter un admin': 'Lisää ylläpitäjä',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Muokkaa konsulttia',
  'Liste des clients': 'Asiakasluettelo',
  'Nom client': 'Asiakkaan nimi',
  Vert: 'Vihreä',
  'Mots clés manquants': 'Puuttuvat avainsanat',
  Lao: 'Lao',
  Somali: 'Somali',
  'Nb de silo par texte': 'Siilojen lukumäärä tekstiä kohti',
  Italien: 'Italian',
  'La commande a été mis à jour avec succès !':
    'Tilaus on päivitetty onnistuneesti!',
  Bosniaque: 'Bosnian',
  'Thématiques d\u2019expertise': 'Asiantuntijuuden teemat',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Tämä on unohtamasi salasana',
  'a été désactivé avec succès': 'on onnistuneesti poistettu käytöstä',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Tilauksen hakeminen',
  'mot-clé': 'avainsana',
  Abkhazien: 'Abhasian',
  Mongolien: 'Mongolian',
  'Typix - Fiche site web': 'Typix - Verkkosivusto',
  'Mail envoyé': 'Lähetetty posti',
  Géorgien: 'Georgian',
  'Si des phrases ne sont pas dans un français correct':
    'Jos lauseet eivät ole oikeaa ranskaa',
  'En cours': 'Käynnissä',
  voir: 'katso',
  "Date d'ajout": 'Lisätty päivämäärä',
  'Tarif au mot': 'Hinta sanaa kohti',
  Thai: 'Thai',
  'Détails du texte': 'Tekstin yksityiskohdat',
  'Commandes en retard': 'Myöhästyneet tilaukset',
  disponible: 'saatavilla',
  Droit: 'Laki',
  Azerbaïdjanais: 'Azerbaidžan',
  'Ajouter un consultant': 'Lisää konsultti',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Sisältöä toistettaessa tuetaan vain seuraavia kohteita',
  'doit comprendre au moins 8 charactères': 'on oltava vähintään 8 merkkiä',
  'Titre de la commande': 'Tilauksen otsikko',
  'Langues bihari': 'Biharin kielet',
  'Livraison estimé au': 'Arvioitu toimitus mennessä',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Tilauksessa olevien tekstien määrä ei voi olla pienempi kuin kirjoittajalle (kirjoittajille) annettujen tekstien määrä. Muuta ensin komentorivin tiedot',
  'Date de livraison': 'Toimituspäivä',
  'Voulez-vous vraiment supprimer le contrat de': 'Haluatko todella lakkauttaa',
  'Facturation au réel': 'Todellinen laskutus',
  'Gap sémantique': 'Semanttinen aukko',
  Estonien: 'Viron',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maksimi ostokustannus/sana',
  'Détail de la commande': 'Tilauksen tiedot',
  'Export en cours de téléchargement\u2026': 'Vienti ladataan...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Tämä kenttä on pakollinen, ja sen on oltava pienempi tai yhtä suuri kuin tilauksen tekstien kokonaismäärä.',
  Arabe: 'Arabia',
  Bislama: 'Bislama',
  'Mail client': 'Asiakkaan posti',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Otsikko, avainsanat, url, tag, meta-description)',
  'Typix - Nouvelle commande': 'Typix - Uusi tilaus',
  'Révision en attente': 'Arvostelu vireillä',
  'Le consultant': 'Konsultti',
  Entreprise: 'Yritys',
  Aragonais: 'Aragonialainen',
  Kashmiri: 'Kashmiri',
  'a été activé': 'on aktivoitu',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Kansainvälinen apukielten yhdistys)',
  Actualités: 'Uutiset',
  'Votre décision a été prise en compte avec succès':
    'Päätöksesi on onnistuneesti otettu huomioon',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'sisältö odottaa asiakkaan vahvistusta',
  'Fiche du rédacteur': 'Toimittajan tiedosto',
  Outillage: 'Työkalut',
  'Typix - Détails de la commande': 'Typix - Tilauksen tiedot',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Lisää tai täydennä sisältösi alla:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'sisällön toistoa odottava toisto-editori',
  'La commande a été supprimé avec succès': 'Tilaus poistettiin onnistuneesti',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Ranskalaiset ilmaisut tai fraasit, jotka eivät sovi sinulle.',
  Corse: 'Korsika',
  Modifier: 'Muokkaa',
  'Nb de GMB': 'GMB:iden lukumäärä',
  Oromo: 'Oromo',
  Polish: 'Puolan',
  'Insérez votre gap sémantique avec l’occurrence':
    'Lisää semanttinen aukkosi esiintymän',
  'Nb de textes à optimiser': 'Optimoitavien tekstien määrä',
  Mode: 'Tila',
  Akan: 'Akan',
  'Révisions effectués': 'Tehdyt tarkistukset',
  'Modifier l\u2019admin': 'Muokkaa admin',
  'Télécharger le document': 'Lataa asiakirja',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Toimittajien huomautukset',
  Quechua: 'Quechua',
  Féroïen: 'Färsaaret',
  'Modifier le rédacteur': 'Vaihda editoria',
  Mail: 'Posti',
  Rédacteurs: 'Kirjailijat',
  'Typix - Détails du texte': 'Typix - Tekstin tiedot',
  Hongrois: 'Unkarilainen',
  'Bon pour livraison': 'Hyvä toimitus',
  'Au réel': 'Todellisessa maailmassa',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Heprea',
  'Nombre total de mots ajoutés :': 'Lisättyjen sanojen kokonaismäärä :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Sen on oltava pienempi kuin enimmäismäärä',
  'Nom du texte': 'Tekstin nimi',
  'Ajouter un rédacteur': 'Lisää toimittaja',
  Ou: 'Tai',
  Utilisateurs: 'Käyttäjät',
  Heures: 'Tunnit',
  'Santé/Bien-être': 'Terveys/hyvinvointi',
  'Interlingue ; Occidental': 'Kieltenvälinen; länsimainen',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Latvian',
  Anglais: 'Englanti',
  Envoyer: 'Lähetä',
  'Tableau de bord': 'Kojelauta',
  'Demande de révision': 'Tarkistuspyyntö',
  'Résultat sémantique': 'Semanttinen tulos',
  'Chercher un rédacteur': 'Toimittajan etsiminen',
  'Ce champs est obligatoire': 'Tämä kenttä on pakollinen',
  Rédaction: 'Toimitus',
  'Modifier le client': 'Muokkaa asiakasta',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Haluatko todella poistaa editorin',
  Titre: 'Otsikko',
  Langue: 'Kieli',
  'Les demandes suivantes ne seront pas prises en compte':
    'Seuraavia hakemuksia ei oteta huomioon',
  'délai dépassé': 'myöhästynyt määräaika',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Olemassa oleva sopimus, muuttakaa tietoja',
  Igbo: 'Igbo',
  Yiddish: 'Jiddish',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Validoidut tilaukset',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Vaihe',
  'Energie/Environnement': 'Energia/Ympäristö',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'ei saa sisältää nimeäsi/etunimeäsi',
  'Typix - Fiche rédacteur': 'Typix - Kirjoittajan tiedosto',
  'révisions effectuées en attentes de validation':
    'validointia odottavat tarkistukset',
  'Textes validés': 'Validoidut tekstit',
  Art: 'Taide',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Monipuolinen',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Tekstien kokonaismäärä',
  'Nombre de mots gérés': 'Hallinnoitujen sanojen määrä',
  "L'émail": 'Emali',
  'Afficher les résultats': 'Näytä tulokset',
  'Frison occidental': 'länsifriisiläinen',
  'Modification effectuée avec succès': 'Muutos onnistuneesti suoritettu',
  'Balise titre': 'Otsikkotunniste',
  'Typix - Mon profil': 'Typix - Oma profiili',
  'email invalide': 'virheellinen sähköpostiosoite',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Lisää url, jotta voit tallentaa tekstin luonnoksena.',
  Macédonien: 'Makedonia',
  'Votre candidature a bien été envoyée': 'Hakemuksesi on lähetetty',
  'E-mail': 'Sähköposti',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Lisää tai täydennä sisältösi alla',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Avainsanojen lisääminen jäljellä olevalla taajuudella',
  'Fiche du site web': 'Verkkosivusto',
  Contacter: 'Ota yhteyttä',
  Fidjien: 'Fidžiläinen',
  Déconnexion: 'Kytkennän katkaisu',
  email: 'sähköpostiosoite',
  'Nombre de textes': 'Tekstien määrä',
  'Enregistrer et informer client':
    'Asiakkaiden rekisteröinti ja tiedottaminen',
  'Date de validation': 'Validointipäivä',
  'Tonga (Îles Tonga)': 'Tonga (Tongan saaret)',
  Javanais: 'Javanilainen',
  Portugais: 'Portugalin',
  Biélorusse: 'Valko-Venäjän',
  'Pour ajouter un nouveau texte': 'Uuden tekstin lisääminen',
  'Enfant/Puéri': 'Lapsi/Puperium',
  'Mots clés': 'Avainsanat',
  Emploi: 'Työllisyys',
  'Voulez-vous vraiment supprimer la commande de': 'Haluatko todella poistaa',
  'Mot clé': 'Avainsana',
  Consultants: 'Konsultit',
  Volapük: 'Volapük',
  Services: 'Palvelut',
  'mot-clé (fréquence)': 'avainsana (taajuus)',
  'Ne plus afficher ce message': 'Älä näytä tätä viestiä uudelleen',
  Consultant: 'Konsultti',
  "Une erreur s'est produite": 'On tapahtunut virhe',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Vähintään 8 merkkiä, joissa on iso kirjain, numero, erikoismerkki ja erikoismerkki',
  'Pushto ; Pashto': 'Pushto; Pashto',
  Français: 'Ranskan',
  validées: 'validoitu',
  'Url du site': 'Sivuston Url',
  Précédent: 'Edellinen',
  'car celui-ci a déjà rédigé des textes':
    'koska jälkimmäinen on jo laatinut tekstejä',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Luonnokset',
  Ido: 'Ido',
  'Contenu disponible': 'Saatavilla oleva sisältö',
  Enseignement: 'Opetus',
  'a été livré avec succès': 'on toimitettu onnistuneesti',
  Chinois: 'Kiinalainen',
  Vietnamien: 'Vietnamilainen',
  'Nouveau client': 'Uusi asiakas',
  'Votre profil a été mis à jour avec succès.':
    'Profiilisi on päivitetty onnistuneesti.',
  Consignes: 'Ohjeet',
  'Mot de passe': 'Salasana',
  Annuler: 'Peruuta',
  Non: 'Ei',
  Nom: 'Nimi',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'verkkosivustoon liittyy tilauksia',
  Score: 'Pisteet',
  'Mot de passe mis à jour avec succès': 'Salasana päivitetty onnistuneesti',
  Latin: 'Latinankielinen',
  Technologie: 'Teknologia',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'on luotu onnistuneesti',
  Roumain: 'Romanian',
  Assamais: 'Assamilainen',
  'Khmer central': 'Keski-Khmeri',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analyysi/raja',
  Grec: 'Kreikkalainen',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Minun projektini',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'avainsana1 (2)\navainsana2 (1)\navainsana3 (1)\navainsana4 (2)\n...',
  'ajouter un texte': 'lisätä tekstiä',
  'Détail de la prestation': 'Palvelun yksityiskohdat',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Sinulla on mahdollisuus viedä yhden tai useamman tilauksen raportti. Voit viedä kaikki asiakkaan tilaukset napsauttamalla keltaista latauskuvaketta.',
  'Gestion des utilisateurs': 'Käyttäjien hallinta',
  'livré et inférieur au nombre de texte non affecté':
    'ja vähemmän kuin jakamattomien tekstien määrä.',
  'Date deadline validation client': 'Asiakkaan validoinnin määräaika',
  Aymara: 'Aymara',
  Validées: 'Validoitu',
  Notes: 'Huomautukset',
  'Nombre de GMB': 'GMB:iden lukumäärä',
  'résultats sur': 'tulokset',
  'Prénom / Nom du client': 'Etunimi / Asiakkaan nimi',
  'Tout télécharger': 'Lataa kaikki',
  'Champs obligatoires': 'Pakolliset kentät',
  Éditer: 'Muokkaa',
  'Coller le texte initial dans le champs ci-dessous':
    'Liitä alkuperäinen teksti alla olevaan kenttään',
  'ID rédacteur': 'Toimittajan tunnus',
  Arménien: 'Armenian',
  'Grande distribution': 'Laajamittainen jakelu',
  Welsh: 'Welsh',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semanttinen rikastaminen',
  'Détail de la prestation du contenu': 'Sisällön toimittamisen yksityiskohdat',
  Noir: 'Musta',
  'Ajouter un texte': 'Lisää teksti',
  Traduction: 'Käännös',
  'Immo/Defisc': 'Omaisuus/Verotus',
  'Brief détaillé': 'Yksityiskohtainen lyhyt kuvaus',
  'Séparez les mots-clés par un Enter .': 'Erottele avainsanat Enterillä .',
  Prix: 'Palkinnot',
  'mot de passe oublié': 'Unohtunut salasana',
  'Ndebele, South ; South Ndebele': 'Ndebele, Etelä; Etelä-Ndebele',
  'Demander une révision': 'Pyydä arvostelua',
  'Demander une repasse globale': 'Pyytää kokonaisvaltaista tarkistusta',
  'Sotho, Southern': 'Sotho, eteläinen',
  'Le titre de la commande existe déjà': 'Tilauksen nimi on jo olemassa',
  'Félicitations !': 'Onnittelut!',
  Kurde: 'Kurdi',
  Adresse: 'Osoite',
  Espagnol: 'Espanjan',
  Ndonga: 'Ndonga',
  'En retard': 'Myöhäinen',
  'a été ajouté avec succès': 'on onnistuneesti lisätty',
  Commandes: 'tilaukset',
  'a été mis en brouillon': 'on laadittu',
  'Rédacteur attribué': 'Nimetty toimittaja',
  Rechercher: 'Etsi',
  'Nouvelle commande': 'Uusi tilaus',
  'Commandes non attribuées': 'Kohdentamattomat tilaukset',
  'Le texte a été mis en brouillon': 'Teksti on laadittu',
  'Contact edito': 'Ota yhteyttä edito',
  Langues: 'Kielet',
  Orange: 'Oranssi',
  'Nynorsk norvégien': 'norjaksi Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmenistan',
  'contenu repassé, en attente validation client':
    'silitetty sisältö, joka odottaa asiakkaan vahvistusta',
  'Nombre de mots rédigés': 'Kirjoitettujen sanojen määrä',
  Malay: 'Malaiji',
  'Nombre de Silo': 'Siilon määrä',
  Oui: 'Kyllä',
  Automobile: 'Autoteollisuus',
  Résultats: 'Tulokset',
  Afar: 'Afar',
  'Tâche - id tâche': 'Tehtävä - tehtävän tunnus',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Tämä kenttä on pakollinen ja sen on oltava suurempi kuin 0.',
  'Vous êtes sûr': 'Oletko varma, että',
  'Réinitialiser votre mot de passe': 'Nollaa salasanasi',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien':
    'Bokmål, norjalainen; norjalainen Bokmål',
  'Nombre de Jours': 'Päivien lukumäärä',
  'Min/Max mots': 'Min/Max sanat',
  Slovène: 'Slovenian',
  Site: 'Verkkosivusto',
  'Sinhala ; Cingalais': 'Sinhala; singhalesialainen',
  Bulgare: 'Bulgarian',
  'Liste des commandes': 'Luettelo tilauksista',
  "L'utilisateur": 'Käyttäjä',
  Uzbek: 'Uzbekistan',
  'Titre du texte': 'Tekstin otsikko',
  Postuler: 'Hae osoitteessa',
  'Nb de textes par Silo': 'Tekstien lukumäärä siiloa kohti',
  'Nombre total de mots (existants et ajoutés)':
    'Sanojen kokonaismäärä (olemassa olevat ja lisätyt)',
  'Max.': 'Max.',
  Beauté: 'Kauneus',
  Humanitaire: 'Humanitaarinen',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte':
    'Etsi kirjoittajan tai tekstin perusteella',
  'Balise meta-description': 'Meta description tag',
  Malgache: 'Malagasy',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Tämän kentän on oltava suurempi kuin 0 ja pienempi kuin 1.',
  'Le rédacteur': 'Toimittaja',
  'a été mis à jour avec succès': 'on onnistuneesti päivitetty',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Vähintään 8 merkkiä, joissa on iso kirjain, numero tai symboli ja jotka eivät ole identtisiä nimen kanssa.',
  'textes en plus': 'lisää tekstejä',
  'Format souhaité': 'Haluttu muoto',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Tilausrivin tiedot',
  'Veuillez préciser la date': 'Täsmennä päivämäärä',
  Pali: 'Pali',
  Clients: 'Asiakkaat',
  'Total facturé': 'Laskutettu yhteensä',
  'contenu validé par le client': 'asiakkaan vahvistama sisältö',
  'Occitan (post 1500)': 'oksiittia (vuoden 1500 jälkeen)',
  Japonais: 'Japanilainen',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Haluatko todella aktivoida',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Kaikki ilmoitukset',
  Marathi: 'Marathi',
  Persan: 'Persialainen',
  'La commande pour le client': 'Asiakkaan tilaus',
  'Intitulé de la commande': 'Tilauksen otsikko',
  Samoan: 'Samoalainen',
  Autres: 'Muut',
  Sport: 'Urheilu',
  "il y'a des commandes liés au site web ": 'verkkosivustoon liittyy tilauksia',
  'Ajout de mots-clé avec la fréquence restante':
    'Avainsanojen lisääminen jäljellä olevalla taajuudella',
  'a été supprimé avec succès': 'on onnistuneesti poistettu',
  Postulé: 'Lähetetty',
  Literie: 'Vuodevaatteet',
  'Édité par le client': 'Asiakkaan toimittama',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Tekstit',
  'Soumettre un nouveau texte': 'Lähetä uusi teksti',
  Téléphone: 'Puhelin',
  Deadline: 'Määräaika',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'On tapahtunut virhe. Tarkista syöttösi tai yritä myöhemmin uudelleen.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Pyyntö luoton lisäämisestä lähetetään',
  'Le rédacteur ': 'Toimittaja',
  'Demander des crédits': 'Luoton hakeminen',
  "Cette commande n'existe pas": 'Tätä komentoa ei ole olemassa',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitilainen',
  Admins: 'Ylläpitäjät',
  'Mettez des mots-clés avec fréquence': 'Aseta avainsanat taajuudella',
  Notifications: 'Ilmoitukset',
  'en retard': 'myöhään',
  "l'admin": 'ylläpitäjä',
  'Mail rédacteurs': 'Mail-toimittajat',
  Amharique: 'Amharic',
  Societe: 'Yritys',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Lisättyjen tekstien määrä',
  Société: 'Yritys',
  Yoruba: 'Joruba',
  'Le texte a été livré avec succès': 'Teksti toimitettiin onnistuneesti',
  'Mettez votre texte': 'Laita tekstisi',
  "Oups....! Une erreur s'est produite": 'Hups....! On tapahtunut virhe',
  'Prénom/Nom': 'Etunimi/sukunimi',
  'Max doit être sup à Min': 'Max on oltava suurempi kuin Min',
  'Importer un autre document': 'Tuo toinen asiakirja',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Hakemuksesi on vanhentunut ... tee uusi hakemus.',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Korjatkaa semanttisen aukon muoto, jotta voitte tallentaa tekstin luonnoksena.',
  Exemple: 'Esimerkki',
  Slovaque: 'Slovakian',
  Finance: 'Rahoitus',
  Réactiver: 'Aktivoi uudelleen',
  'Nb textes': 'Tekstien lukumäärä',
  'Intitulé commande': 'Tilauksen nimi',
  Supprimer: 'Poista',
  Brouillon: 'Luonnos',
  'Mots clés restants': 'Jäljellä olevat avainsanat',
  Date: 'Päivämäärä',
  Rôle: 'Rooli',
  'Ce champs doit être sup à 0': 'Tämän kentän on oltava suurempi kuin 0',
  'Maison/Déco': 'Koti/koriste',
  'Vous avez': 'Sinulla on',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Tätä komentoriviä ei voida poistaa, koska osa sen tekstistä on toimitettu.',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'tarkista sähköpostisi ja klikkaa linkkiä jatkaaksesi!',
  Sarde: 'Sardinia',
  'Note rédacteur': 'Toimittajan huomautus',
  'Uighur ; Uyghur': 'uiguuri; uiguuri',
  Name: 'Nimi',
  'Commandes intelligentes': 'Smart komennot',
  'Commandes avec révision': 'Smart + review komennot',
  'Type de contenu': 'Sisältötyyppi',
  'Fiche produit': 'Tuotetiedot',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium-tekstien kirjoitus',
  'Rédaction avec révision': 'Smart + review tekstien kirjoitus',
  'Rédaction intelligente': 'Smart tekstien kirjoitus',
  PREMIUM: 'PREMIUM',
  Type: 'Tyyppi',
  Editer: 'Muokkaa',
  'Tous les types': 'Kaikki tyypit',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Käsitellyt laskut',
  'Factures à valider': 'Laskuja tarkistettavana',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Laskun luominen ei onnistu! Yritä uudelleen',
  'Récapitulatif de facturation': 'Laskutuksen yhteenveto',
  'Demander une modification': 'Pyytää muutosta',
  'Valider et envoyer la facture': 'Hyväksy ja lähetä lasku',
  'Demande de modification': 'Muutoksenpyyntö',
  'La facture a été validé avec succès!': 'Lasku on hyväksytty onnistuneesti!',
  "Oups un erreur c'est produit!": 'Oops, tapahtui virhe!',
  'Nombre de texte': 'Tekstien lukumäärä',
  'Nombre de mot par texte :': 'Sanamäärä per teksti:',
  Paiement: 'Maksu',
  Virement: 'Pankkisiirto',
  Délai: 'Maksuaika',
  'Référence de votre facture': 'Laskunne viite',
  'Coût au mot': 'Sanahinta',
  'Montant global de la commande': 'Tilauksen kokonaismäärä',
  'Votre facture': 'Teidän laskunne',
  'Importer votre facture': 'Tuo laskunne',
  'Pas de fichier disponible': 'Tiedostoa ei ole saatavilla',
  Télécharger: 'Lataa',
  Relancer: 'Uudelleenlähetä',
  'Générer la facture': 'Luo lasku',
  Payée: 'Maksettu',
  'En cours de paiement': 'Maksu käynnissä',
  'En cours de validation': 'Validointi käynnissä',
  'A envoyer': 'Lähetettävä',
  'Modification demandée': 'Muutosta pyydetty',
  'Référence de facture est obligatoire': 'Laskun viite on pakollinen',
  'Le coût au mot doit être un nombre': 'Sanahinnan on oltava numero',
  'Le coût au mot doit être un nombre positif':
    'Sanahinnan on oltava positiivinen numero',
  'Le coût au mot est obligatoire': 'Sanahinta on pakollinen',
  'Le montant global de la commande doit être un nombre':
    'Tilauksen kokonaismäärän on oltava numero',
  'Le montant global de la commande doit être un nombre positif':
    'Tilauksen kokonaismäärän on oltava positiivinen numero',
  'Le montant global de la commande est obligatoire':
    'Tilauksen kokonaismäärä on pakollinen',
  'Bill reference existe déja , veuillez réessayer':
    'Laskun viite on jo olemassa, yritä uudelleen',
  'La facture a été envoyée avec succès!': 'Lasku on lähetetty onnistuneesti!',

  'Montant estimé': 'Arvioitu määrä',
  Action: 'Toiminta',
  Facture: 'Lasku',
  Statut: 'Tila',
  Factures: 'Laskut',
  'Valider la facture': 'Vahvista lasku',
  'La relance a été envoyée avec succès !':
    'Muistutus on lähetetty onnistuneesti!',
  'La demande de modification a été envoyée avec succès !':
    'Muutospyyntö on lähetetty onnistuneesti!',
  'Le cout est trop élevé': 'Kustannukset ovat liian korkeat',
  'Le montant global de la commande est trop élevé ':
    'Tilauksen kokonaismäärä on liian korkea',
  jours: 'päivää',
  'Factures à envoyer': 'Laskut lähetettäväksi',
  'Valider le paiement': 'Vahvista maksu',
  'Générer une facture': 'Generoi lasku',
  'Montant rédacteur': 'Kirjoittajan summa',
  'Montant estimé par Typix': 'Typixin arvioima summa',
  'Voir la modification': 'Näytä muutos',

  'Tag de Titre': 'Otsikkotagi',
  'Meta description': 'Meta-kuvaus',
  'Nom du produit': 'Tuotteen nimi',
  Avantages: 'Edut',
  'Description détaillée': 'Yksityiskohtainen kuvaus',
  'En cours de modification': 'Muokkauksessa',
  'Mes Factures': 'Omat laskuni',
  'En attente de validation': 'Odottaa hyväksyntää',
  'les valeurs négatives ne sont pas autorisées':
    'Negatiiviset arvot eivät ole sallittuja.',
  'Texte Seulement': 'Vain teksti',
  'Traduction Seulement': 'Vain käännös',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Muistutus voidaan lähettää 20 päivän kuluttua laskun lähettämisestä',
  Instructions: 'Ohjeet',
  'Votre avis pour la commande': 'Arviosi tilauksesta',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Arviosi on arvokas meille! Kiitos!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Arviosi lähettämisessä tapahtui virhe. Yritä myöhemmin uudelleen!',
  Evaluer: 'Arvioida',
  Commentaire: 'Kommentti',
  'Votre commantaire': 'Kommenttisi',
  Note: 'Arvosana',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Palautteesi on meille arvokasta ja auttaa meitä parantamaan palveluitamme. Kiitos, että jaat arviosi kanssamme!',
  'Verifier la facture': 'Tarkista lasku',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Palautteenne on meille arvokasta ja auttaa meitä parantamaan palvelujamme.',
  'Notes rédacteur': 'Kirjoittajan huomautukset',
  'Avis client': 'Asiakasarvio',
  'Pas de commantaire': 'Ei kommenttia',
  "Qu'avez vous pensé de votre commande?": 'Mitä ajattelitte tilauksestanne?',
  'Evaluation de la commande': 'Tilauksen arviointi',
  'Rédiger un commentaire': 'Kirjoita kommentti',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Ottakaa osaa ja auttakaa meitä tarjoamaan teille korkealaatuisia kirjoituspalveluja jakamalla palautteenne.',
  'Que pouvons-nous améliorer?': 'Mitä voimme parantaa?',
  Continuer: 'Jatkaa',
  'Le dernier texte a été validé avec succès':
    'Viimeisin teksti hyväksyttiin onnistuneesti',
  'Rechercher par rédacteur, commandes, et montant':
    'Etsi kirjoittajan, tilausten ja summan mukaan',
  obligatoire: 'pakollinen',
  'Note admin': 'Ylläpitäjän huomautus',
  'Note client': 'Asiakkaan huomautus',
  Partie: 'Osa',
  'Vous venez de valider tous les contenus rédigés par ':
    'Olet juuri hyväksynyt kaiken sisällön, jonka on kirjoittanut ',
  "Qu'en avez vous pensé": 'Mitä mieltä olit siitä',
  'La facture a été générée avec succès !': 'Lasku on luotu onnistuneesti!',
  'Demande de révision globale': 'Vaadi globaalia tarkastelua',
  Valider: 'Hyväksyä',
  'La taille du titre et/ou la méta-description est trop courte':
    'Otsikon ja/tai meta-kuvauksen pituus on liian lyhyt',

  'Obligatoire en cas d’optimisation ou traduction':
    'Pakollinen optimoinnin tai käännöksen tapauksessa',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Kommenttinne...',
  'Ex : "Contenus de très bonne qualité':
    'Esimerkki: "Erittäin hyvälaatuista sisältöä"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Voisitteko, ole hyvä, valita tämä kirjoittaja tuleviin tilauksiin?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Tämä painike on tarkoitettu ilmoittamaan asiakkaalle, että alkuperäinen teksti sisälsi jo avainsanasuoritusta',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Muokkaa tekstiä',
  Ajouter: 'Lisää',
  'Demande examen': 'Pyyntö tentti',
  'Recherche de mots clés à haute densité': 'Avainsanojen täyttämisen tutkimus',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Hups, näyttää siltä, että tekstissäsi on keyword stuffing: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Hups, näyttää siltä, että alkuperäisessä tekstissä on keyword stuffing (liian monta esiintymää yhdestä tai useammasta avainsanasta).',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Varmista, että avainsanan tiheys on optimoitu SEO:ta varten (enintään yksi esiintyminen jokaista 50 sanaa kohti).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Tätä varten älä muokkaa olemassa olevaa tekstiä ja lähetä ystävällisesti tutkimuspyyntö tukipalveluun.',
  'Votre demande a été bien envoyée !': 'Pyyntönne on lähetetty onnistuneesti!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Ongelma ilmeni lähettäessä pyyntöänne.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Odota avainsanan täyttämisen analysointia.',
  'Inserez le maillage interne': 'Lisää sisäinen linkitys',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Varmista, että avainsanatiheys on optimoitu SEO:ta varten (enintään yksi esiintyminen jokaista 50 sanaa kohden).\nJos kohtaat sanan, jolla voi olla useita merkityksiä (Esimerkki: Former / Formation / Form tai Matelas / Matelassé), voit pyytää tarkistusta tukipalvelusta.',
  'Mots clés à haute densité dans le texte actuel':
    'Korkean tiheyden avainsanat nykyisessä tekstissä',
  'Mots clés à haute densité dans le texte initial':
    'Korkean tiheyden avainsanat alkuperäisessä tekstissä',
  'Texte en cours de rédaction !': 'Tekstiä kirjoitetaan parhaillaan !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Oletko varma, että haluat vahvistaa tekstin? Varmista ensin, että olet tallentanut muutokset.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Viime hetken tietoa! Luottosaldosi loppuu pian :( Ennakoimiseksi pyydä rohkeasti lisää luottoja nyt tilauksesi loppuunsaattamiseksi.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Haluatko vahvistaa nykyisen URL-osoitteen vai muuttaa sitä?',
  'Veuillez fournir une URL valide': 'Anna kelvollinen URL-osoite.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Ole hyvä ja anna alla olevaan kenttään sivun URL-osoite, jossa sisältö on integroitu:',
  'Contenu en ligne': 'Verkkosisältö',
  'Télécharger le document de révision': 'Lataa tarkistusdokumentti',
  'Date d’envoi': 'Lähetyspäivä',
  Information: 'Tieto',
  Enregistrer: 'Tallenna',
  'Textes en ligne': 'Tekstit verkossa',
  'Livrer les textes': 'Toimita tekstit',
  Indisponible: 'Ei saatavilla',
  Disponible: 'Saatavilla',
  'Les textes ont été livrés avec succès.':
    'Tekstit on toimitettu onnistuneesti.',
  'Le texte a été livré avec succès.': 'Teksti on toimitettu onnistuneesti.',
  'Les textes ont été validés avec succès.':
    'Tekstit on vahvistettu onnistuneesti.',
  'Le texte a été validé avec succès.': 'Teksti on vahvistettu onnistuneesti.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Haluatko todella vahvistaa tilauksen tekstit?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Haluatko todella toimittaa tilauksen tekstit?',
  'Fiche produit longue': 'Pitkä tuotekortti',
  'Fiche produit courte': 'Lyhyt tuotekortti',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Teksti on liian pitkä eikä sitä voida viedä Exceliin.',
};
