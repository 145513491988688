import React from 'react';
import { DropdownMenu } from 'components/shared/DropDown/DropDownMenu';
import pdf_FR from 'components/TutorialDocuments/Typix_Tutorial_Version_FR_client.pdf';
import pdf_EN from 'components/TutorialDocuments/Typix_Tutorial_Version_EN_client.pdf';

import pdf_EN_WRITER from 'components/TutorialDocuments/Tuto_process_Typix_global_EN_writer.pdf';
import pdf_FR_WRITER from 'components/TutorialDocuments/Tuto_process_Typix_global_FR_writer.pdf';
import pdf_Facturation_FR from 'components/TutorialDocuments/Tuto_Facturation_FR_writer.pdf';
import pdf_Mise_En_Ligne from 'components/TutorialDocuments/Tuto_Mise_en_ligne_des_contenus.pdf';

export const DownloadMenu = ({ user }) => {
  const clientOptions = [
    {
      href: pdf_FR,
      download: 'Typix_Tutorial_Version_FR_client',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto process Typix global (FR)</span>
        </div>
      ),
    },
    {
      href: pdf_EN,
      download: 'Typix_Tutorial_Version_EN_client',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto process Typix global (EN)</span>
        </div>
      ),
    },
    {
      href: pdf_Mise_En_Ligne,
      download: 'Tuto_Mise_en_ligne_des_contenus',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto mise en ligne des contenus (FR)</span>
        </div>
      ),
    },
  ];

  const writerOptions = [
    {
      href: pdf_EN_WRITER, // Assuming this is the English global process tutorial
      download: 'Tuto_process_Typix_global_EN_writer',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto process Typix global (EN)</span>
        </div>
      ),
    },
    {
      href: pdf_FR_WRITER,
      download: 'Tuto_process_Typix_global_FR_writer',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto process Typix global (FR)</span>
        </div>
      ),
    },
    {
      href: pdf_Facturation_FR,
      download: 'Tuto_Facturation_FR_writer',
      content: (
        <div className="d-flex align-items-center justify-content-between">
          <span>Tuto Facturation (FR)</span>
        </div>
      ),
    },
  ];

  return (
    <>
      {user && user.role === 'CLIENT' ? (
        <DropdownMenu options={clientOptions} />
      ) : user && user.role === 'WRITER' ? (
        <DropdownMenu options={writerOptions} />
      ) : null}
    </>
  );
};

export default DownloadMenu;
