import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Badge } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { BillDisplaySelector } from 'store/Writer/BillDisplay/reducers';
import { BILL_MODIFICATION, BILL_STATUS_NOTGENERATED } from 'utils/Constants';
import './styles.css';
import { getBills } from 'store/Writer/BillDisplay/actions';
import { UserSessionSelector } from '../../../../store/UserSession';

function SideNavBar({ paths }) {
  const { t } = useTranslation();

  const user = useSelector(UserSessionSelector);
  const dispatch = useDispatch();
  const { bills } = useSelector(BillDisplaySelector);
  const [nbOfToValidate, setNbToValidate] = useState(0);
  useEffect(() => {
    dispatch(getBills());
  }, [dispatch]);

  useEffect(() => {
    setNbToValidate(
      bills.filter((bill) =>
        [BILL_STATUS_NOTGENERATED, BILL_MODIFICATION].includes(bill.status)
      ).length
    );
  }, [bills]);

  const [isExpended, setExpended] = useState(false);
  const [currentPage, setCurrentPage] = useState(window.location.pathname);

  useEffect(() => {
    window.addEventListener('locationchange', () => {
      setCurrentPage(window.location.pathname);
    });
  }, []);

  function toggleNavbar() {
    setExpended(!isExpended);
  }

  return (
    <div className="box-menu-left ">
      <div className={`box-radius-3 ${isExpended ? 'expand' : ''}`} />
      <div className="box-radius bg-img-1">
        <Link tag={Link} to={paths.mainMenu}>
          <img
            className="logo-m"
            src="Images/logo-typix.png"
            alt="logo-typix"
          />
        </Link>
      </div>
      <div className={`box-radius-2 ${isExpended ? 'active' : ''}`} />
      <div
        className="wrapper-box-menu-left"
        onMouseEnter={toggleNavbar}
        onMouseLeave={toggleNavbar}
      >
        <Link tag={Link} to={paths.mainMenu}>
          <div
            className={`${
              currentPage === paths.mainMenu
                ? 'box-menu-block bt-icon-dashboard active'
                : 'box-menu-block bt-icon-dashboard'
            }`}
          ></div>
        </Link>
        <Link tag={Link} to={paths.nonAttributedOrders}>
          <div
            className={`${
              currentPage === paths.nonAttributedOrders
                ? 'box-menu-block  bt-icon-non-attr active'
                : 'box-menu-block  bt-icon-non-attr'
            }`}
          ></div>
        </Link>
        <Link tag={Link} to={paths.pendingOrders}>
          <div
            className={`${
              currentPage === paths.pendingOrders
                ? 'box-menu-block bt-icon-encour active'
                : 'box-menu-block bt-icon-encour'
            }`}
          ></div>
        </Link>
        <Link tag={Link} to={paths.lateOrders}>
          <div
            className={`${
              currentPage === paths.lateOrders
                ? 'box-menu-block  bt-icon-enretard active'
                : 'box-menu-block  bt-icon-enretard'
            }`}
          ></div>
        </Link>
        <Link tag={Link} to={paths.validatedOrders}>
          <div
            className={`${
              currentPage === paths.validatedOrders
                ? 'box-menu-block bt-icon-validee active'
                : 'box-menu-block bt-icon-validee'
            }`}
          ></div>
        </Link>

        <Link tag={Link} to={paths.facturation}>
          <Badge badgeContent={nbOfToValidate} color="error">
            <div
              className={`${
                currentPage === paths.facturation
                  ? 'box-menu-block bt-icon-facturation active'
                  : 'box-menu-block bt-icon-facturation'
              }`}
            ></div>
          </Badge>
        </Link>
        {user && user.role === 'ADMIN' && (
          <Link tag={Link} to={paths.analyzer}>
            <Badge color="error">
              <div className={'box-menu-block bt-icon-analyzer'}></div>
            </Badge>
          </Link>
        )}

        <div
          className={`box-sub-menu ${isExpended ? 'active' : ''}`}
          id="sidebar"
        >
          <div className="box-subtext-menu">
            <div className="box-text-menu">Dashboard</div>
            <div className="box-text-menu">{t('Non attribuées')}</div>
            <div className="box-text-menu">{t('En cours')}</div>
            <div className="box-text-menu">{t('En retard')}</div>
            <div className="box-text-menu">{t('Validées')}</div>
            <div className="box-text-menu">{t('Factures')}</div>
            {user && user.role === 'ADMIN' && (
              <div className="box-text-menu">{t('Analyzer')}</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
