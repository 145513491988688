export const norwigianTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Kommandolinjene har blitt oppdatert!',
  'Le texte a été enregistré avec succès': 'Teksten ble vellykket registrert',
  'La traduction a été enregistrée avec succès':
    'Oversettelsen ble lagret vellykket',
  'Texte + Traduction enregistré avec succès':
    'Tekst + Oversettelse ble lagret vellykke',
  'Nombre de mots': 'Antall ord',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Kundens fornavn/etternavn',
  Bashkir: 'Bashkir',
  "La date doit être ultérieure à aujourd'hui.":
    'Datoen må være senere enn i dag.',
  'Contenu rédigé': 'Skriftlig innhold',
  'Nom/prénom': 'Etternavn/fornavn',
  'Mot de passe incorrect': 'Feil passord',
  Institutions: 'Institusjoner',
  'Veuillez donner un descriptif au document téléchargé':
    'Vennligst gi en beskrivelse av det nedlastede dokumentet',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Neste',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"': 'Separer nøkkelord med "enter".',
  Allemand: 'Tysk',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'en e-post med en lenke for tilbakestilling av passord har blitt sendt til e-posten din.',
  'Chef edito': 'Sjefforfatter',
  Croate: 'Kroatisk',
  'Nb de textes': 'Antall tekster',
  Violet: 'Fiolett',
  'Ce champs doit être sup ou égale nombre de texte':
    'Dette feltet må være større enn eller lik antall tekst.',
  'a été activé avec succès': 'er vellykket aktivert',
  Népalais: 'Nepalesisk',
  'Nombre de FAQ': 'Antall vanlige spørsmål',
  Urdu: 'Urdu',
  'Analyses restantes': 'Resterende analyser',
  Indonésien: 'Indonesisk',
  Breton: 'Bretonsk',
  Livrés: 'Leveres',
  Associations: 'Sammenslutninger',
  'Le client': 'Kunden',
  'Révision demandée': 'Anmodning om gjennomgang',
  "Une erreur s'est produite, veuillez réessayer":
    'En feil har oppstått, vennligst prøv igjen',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Uautorisert oppdatering etter hvert som tekster har blitt levert',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'For å legge til tekst (optimalisere eksisterende innhold)',
  Gaélique: 'Gælisk',
  'La ligne de commande pour': 'Kommandolinjen for',
  'Business/Management': 'Virksomhet/ledelse',
  Serbe: 'Serbisk',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Vennligst angi nedenfor dine forespørsler om endringer, med tanke på våre strykebetingelser',
  'rédacteurs trouvés': 'finne forfattere',
  'Kirghiz ; Kirghiz': 'Kirgisisk; Kirgisisk',
  Différence: 'Forskjell',
  'en cours': 'under arbeid',
  'Sami du Nord': 'Nordsamisk',
  Comparer: 'Sammenlign',
  Lituanien: 'Litauisk',
  'Typix - Mon tableau de bord': 'Typix - Mitt dashbord',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Må være minst 8 tegn langt med en stor bokstav, et tall, et spesialtegn og ikke identisk med navnet.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Vennligst fyll inn gyldige felter',
  Basque: 'Baskisk',
  'Exporter .csv': 'Eksporter .csv',
  'Confirmer le nouveau mot de passe': 'Bekreft nytt passord',
  "Pas d'options": 'Ingen alternativer',
  'Banque/Assurance': 'Bank/forsikring',
  'Vos demandes': 'Dine forespørsler',
  'Coût d\u2019achat': 'Innkjøpskostnad',
  Tamil: 'Tamilsk',
  'Le texte a été supprimé avec succès': 'Teksten ble vellykket slettet',
  'Mes Commandes': 'Mine bestillinger',
  Zoulou: 'Zulu',
  Afficher: 'Utsikt',
  Texte: 'Tekst',
  Islandais: 'Islandsk',
  Russe: 'Russisk',
  'Tous les textes': 'Alle tekster',
  Czech: 'tsjekkisk',
  'Comparaison hors ligne': 'Frakoblet sammenligning',
  Téléphonie: 'Telefoni',
  'Valider le texte': 'Valider teksten',
  'Votre text ne de doit pas dépasser 500 mots':
    'Teksten bør ikke overstige 500 ord',
  'Coquilles (double espace, « s »\u2026)': 'Skall (dobbelt mellomrom, "s"...)',
  'le consultant': 'konsulenten',
  Coréen: 'Koreansk',
  'Nb de Silo': 'Antall siloer',
  Validés: 'Validert',
  'non attribuées': 'ikke tildelt',
  Néerlandais: 'Nederlandsk',
  'Voulez-vous vraiment supprimer': 'Vil du virkelig slette',
  Rundi: 'Rundi',
  Regional: 'Regionalt',
  'Compte client': 'Kundekonto',
  Global: 'Globalt',
  'Tous les rôles': 'Alle roller',
  Musique: 'Musikk',
  Perso: 'Personlig',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Det ser ut til at du ikke har mer analysekreditt',
  Bambara: 'Bambara',
  Sortir: 'Kom deg ut',
  optionel: 'valgfritt',
  'Temps restant': 'Gjenstående tid',
  Ukrainien: 'Ukrainsk',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Din anmodning om gjennomgang er sendt.',
  'Typix - Créer rédacteur': 'Typix - Opprett en forfatter',
  "n'existe pas": 'finnes ikke',
  Hindi: 'Hindi',
  'ne peut pas être supprimé car il est lié à des sites web':
    'kan ikke slettes ettersom det er knyttet til nettsteder',
  'Commandes en cours': 'Pågående bestillinger',
  'Textes livrés': 'Leverte tekster',
  Validation: 'Validering',
  'Non attribuées': 'Ikke tildelt',
  'Voulez-vous vraiment désactiver': 'Ønsker du virkelig å deaktivere',
  'Catalan ; Valencien': 'Katalansk; valensiansk',
  'Nombre de textes restants': 'Antall gjenværende tekster',
  'Ne doit pas contenir votre nom': 'Må ikke inneholde navnet ditt',
  Documentation: 'Dokumentasjon',
  Kazakh: 'Kasakhisk',
  'Numéro invalide': 'Ugyldig nummer',
  'Veuillez saisir un texte ou importer un fichier.':
    'Vennligst skriv inn en tekst eller importer en fil.',
  'Nb de FAQ': 'Antall vanlige spørsmål',
  Analyser: 'Analysere',
  Turc: 'Tyrkisk',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Alle forespørsler som ikke er spesifisert i mandatet',
  Wallon: 'Vallonsk',
  Birmane: 'Burmesisk',
  Irlandais: 'Irsk',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Tilbakestill passordet ditt',
  Cuisine: 'Kjøkken',
  'Haïtien ; créole haïtien': 'Haitiansk; haitisk kreolsk',
  'Vous ne pouvez pas supprimer le rédacteur': 'Du kan ikke slette forfattere',
  Validé: 'Validert',
  "cet email n'existe pas": 'denne e-posten finnes ikke',
  Historique: 'Historie',
  Confirmer: 'Bekreft',
  'à cocher si le nombre de mots est différent par texte':
    'krysses av dersom antall ord er forskjellig for hver tekst.',
  'Fiche du site web ': 'Nettstedsark',
  Désactiver: 'Deaktiver',
  'Travaux/BTP': 'Bygge- og anleggsarbeider',
  'La fiche du website': 'Nettstedsarket',
  'Si le contenu ne respecte pas les règles du brief':
    'Hvis innholdet ikke overholder reglene i oppdragsbeskrivelsen',
  Norvégien: 'Norsk',
  '0 rédacteurs': '0 forfattere',
  indisponible: 'ikke tilgjengelig',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Reviderte tekster',
  'Status texte': 'Tekststatus',
  'textes livrés en attente de validation':
    'tekster levert i påvente av validering',
  'Typix - Mon Profile': 'Typix - Min profil',
  'le client': 'klienten',
  Romanche: 'Romanche',
  'URL du site': 'Nettadressen til nettstedet',
  'nouvelles notifications': 'nye meldinger',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; maldivisk; maldivisk',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Er du sikker på at du vil lagre teksten',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Vennligst legg til en innledende tekst for å lagre teksten som et utkast.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Kontrakten for kunden',
  rédacteurs: 'forfattere',
  'Ce champ est obligatoire': 'Dette feltet er obligatorisk',
  'Tarif/Mot': 'Sats/ord',
  Tadjik: 'Tadsjikisk',
  'Réf devis': 'Ref sitat',
  'Rechercher un rédacteur': 'Søk etter en forfatter',
  Albanais: 'Albansk',
  Galicien: 'Galicisk',
  Disponibilité: 'Tilgjengelighet',
  'Les deux mots de passe ne sonts pas identiques':
    'De to passordene er ikke identiske',
  Tibétain: 'Tibetansk',
  'Typix - Gestion des utilisateurs': 'Typix - Brukeradministrasjon',
  'Ossétien ; Ossétique': 'Ossetisk; ossetisk',
  Tswana: 'Tswana',
  'Ajouter un client': 'Legg til en kunde',
  'Séparez les mots-clés par un " Enter ".':
    'Separer nøkkelordene med "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Temaer',
  'Nb mots/texte': 'Antall ord/tekst',
  Commerce: 'Handel',
  'Voir les commandes': 'Se ordrene',
  'Nombre de mots par texte': 'Antall ord per tekst',
  'Lien drive...': 'Link drive...',
  'Hollandais ; Flamand': 'Nederlandsk; flamsk',
  'Mon profil': 'Min profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Dette feltet må være større enn 0 fordi faktureringen ikke er reell.',
  'Le texte a été validé avec succès': 'Teksten har blitt validert',
  Jeux: 'Spill',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Ønsker du virkelig å fjerne kommandolinjen for',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Nord; Ndebele Nord',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'Kirkeslavisk; gammelslavisk; kirkeslavisk; gammelbulgarsk; gammelslavisk; kirkeslavisk',
  Swahili: 'Swahili',
  Voyage: 'Reise',
  'Nombre de mot à ajouter': 'Antall ord som skal legges til',
  'Liste des rédacteurs': 'Liste over forfattere',
  'le rédacteur': 'forfattere',
  Kanuri: 'Kanuri',
  rédacteur: 'forfatter',
  Tatar: 'Tatarisk',
  'Roumain ; Moldave ; Moldovan': 'Rumensk; moldovisk',
  'Le texte initial de': 'Den opprinnelige teksten til',
  'Mail clients': 'Kundepost',
  'Le contrat de': 'Kontrakten med',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'minst én liten og én stor bokstav, ett spesialtegn og ett tall.',
  Transport: 'Transport',
  'Nombre total de mots (existants et ajoutés) :':
    'Totalt antall ord (eksisterende og nye) :',
  'Rechercher par mail, prénom, nom, site...':
    'Søk etter e-post, fornavn, etternavn, nettsted...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Antall ord som skal legges til (Min/Maks) :',
  Compte: 'Konto',
  'Tourisme/Loisirs': 'Turisme/fritid',
  'Nouveau texte': 'Ny tekst',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grønlandsk',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Oops...! Det er en eller flere feil i det semantiske gap-vinduet. Vennligst sjekk innholdet for å rette den/dem og send inn teksten på nytt.',
  'ne peut pas être supprimé pour le moment':
    'kan ikke slettes på dette tidspunktet',
  'Le texte': 'Teksten',
  URL: 'URL',
  "Un utilisateur existe déjà avec l'email":
    'Det finnes allerede en bruker med e-postadressen',
  Cree: 'Cree',
  Prénom: 'Fornavn',
  'commandes prête': 'bestillinger klare',
  Réinitialiser: 'Tilbakestill',
  'Veuillez fournir une date valide': 'Vennligst oppgi en gyldig dato',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Luxembourgsk; Letzeburgesch',
  Suédois: 'Svensk',
  'Confirmer le mot de passe': 'Bekreft passord',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Dette feltet er obligatorisk og kan bare inneholde tall',
  Sciences: 'Vitenskap',
  Tchétchène: 'Tsjetsjensk',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'er den eneste klienten for et nettsted, må andre legges til for å fjerne det.',
  'Le mot de passe de confirmation ne correspond pas':
    'Bekreftelsespassordet samsvarer ikke',
  Swati: 'Swati',
  Danois: 'Dansk',
  Sauvegarder: 'Lagre',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Vennligst endre følgende informasjon: tittel og/eller nøkkelord',
  'importer un document': 'importere et dokument',
  Maltais: 'Maltesisk',
  'Nb de mots': 'Antall ord',
  'Les données fournies sont invalides': 'De oppgitte dataene er ugyldige',
  'Livraison estimée': 'Estimert levering',
  'Grec moderne (1453-)': 'Moderne gresk (1453-)',
  Avestan: 'Avestan',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Totalt antall ord lagt til',
  'le contenu présente des inexactitudes':
    'innholdet inneholder unøyaktigheter',
  Finnois: 'Finsk',
  'Ajouter un admin': 'Legg til en administrator',
  'Min/Max': 'Min/Maks',
  'Modifier le consultant': 'Endre konsulenten',
  'Liste des clients': 'Liste over kunder',
  'Nom client': 'Kundens navn',
  Vert: 'Grønn',
  'Mots clés manquants': 'Manglende nøkkelord',
  Lao: 'Lao',
  Somali: 'Somalisk',
  'Nb de silo par texte': 'Antall siloer per tekst',
  Italien: 'Italiensk',
  'La commande a été mis à jour avec succès !':
    'Bestillingen har blitt oppdatert!',
  Bosniaque: 'Bosnisk',
  'Thématiques d\u2019expertise': 'Temaer for ekspertise',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Dette er passordet du har glemt',
  'a été désactivé avec succès': 'har blitt deaktivert',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Søk om bestillingen',
  'mot-clé': 'nøkkelord',
  Abkhazien: 'Abkhasisk',
  Mongolien: 'Mongolsk',
  'Typix - Fiche site web': 'Typix - Nettsted',
  'Mail envoyé': 'Post sendt',
  Géorgien: 'Georgisk',
  'Si des phrases ne sont pas dans un français correct':
    'Hvis setningene ikke er på korrekt fransk',
  'En cours': 'Under arbeid',
  voir: 'se',
  "Date d'ajout": 'Dato lagt til',
  'Tarif au mot': 'Pris per ord',
  Thai: 'Thai',
  'Détails du texte': 'Detaljer om teksten',
  'Commandes en retard': 'Forsinkede bestillinger',
  disponible: 'tilgjengelig',
  Droit: 'Lovgivning',
  Azerbaïdjanais: 'Aserbajdsjansk',
  'Ajouter un consultant': 'Legg til en konsulent',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Ved avspilling av innhold støttes bare følgende elementer',
  'doit comprendre au moins 8 charactères': 'må inneholde minst 8 tegn',
  'Titre de la commande': 'Tittel på bestillingen',
  'Langues bihari': 'Bihari-språk',
  'Livraison estimé au': 'Anslått levering innen',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Antallet tekster i bestillingen kan ikke være mindre enn antallet tekster som er tildelt skribenten(e). Vennligst endre informasjonen i kommandolinjen først',
  'Date de livraison': 'Leveringsdato',
  'Voulez-vous vraiment supprimer le contrat de':
    'Ønsker du virkelig å avskaffe',
  'Facturation au réel': 'Faktisk fakturering',
  'Gap sémantique': 'Semantisk gap',
  Estonien: 'Estisk',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maks innkjøpskostnad/ord',
  'Détail de la commande': 'Bestillingsdetaljer',
  'Export en cours de téléchargement\u2026': 'Eksporten lastes opp...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Dette feltet er obligatorisk og må være mindre enn eller lik totalt antall tekster i bestillingen.',
  Arabe: 'Arabisk',
  Bislama: 'Bislama',
  'Mail client': 'Kundepost',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Tittel, nøkkelord, url, tag, metabeskrivelse)',
  'Typix - Nouvelle commande': 'Typix - Ny ordre',
  'Révision en attente': 'Gjennomgang pågår',
  'Le consultant': 'Konsulenten',
  Entreprise: 'Selskap',
  Aragonais: 'Aragonesisk',
  Kashmiri: 'Kashmiri',
  'a été activé': 'har blitt aktivert',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (den internasjonale sammenslutningen for hjelpespråk)',
  Actualités: 'Nyheter',
  'Votre décision a été prise en compte avec succès':
    'Beslutningen din er tatt i betraktning',
  Afrikaans: 'Afrikaans',
  'contenu en attente de vérification client':
    'innhold som venter på kundebekreftelse',
  'Fiche du rédacteur': 'forfatteres fil',
  Outillage: 'Verktøy',
  'Typix - Détails de la commande': 'Typix - Bestillingsdetaljer',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Legg til eller fullfør innholdet nedenfor:',
  Ewe: 'Får',
  'contenu en attente de repasse rédacteur':
    'innhold i påvente av avspillingsredigering',
  'La commande a été supprimé avec succès':
    'Bestillingen ble vellykket slettet',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Franske uttrykk eller setninger som ikke passer deg',
  Corse: 'Korsika',
  Modifier: 'Rediger',
  'Nb de GMB': 'Antall GMB',
  Oromo: 'Oromo',
  Polish: 'Polsk',
  'Insérez votre gap sémantique avec l’occurrence':
    'Sett inn ditt semantiske gap med forekomsten',
  'Nb de textes à optimiser': 'Antall tekster som skal optimaliseres',
  Mode: 'Modus',
  Akan: 'Akan',
  'Révisions effectués': 'Utførte revisjoner',
  'Modifier l\u2019admin': 'Rediger administratoren',
  'Télécharger le document': 'Last ned dokumentet',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'forfatteres merknader',
  Quechua: 'Quechua',
  Féroïen: 'Færøysk',
  'Modifier le rédacteur': 'Endre forfatter',
  Mail: 'Post',
  Rédacteurs: 'Forfattere',
  'Typix - Détails du texte': 'Typix - Tekstdetaljer',
  Hongrois: 'Ungarsk',
  'Bon pour livraison': 'God for levering',
  'Au réel': 'I den virkelige verden',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebraisk',
  'Nombre total de mots ajoutés :': 'Totalt antall ord lagt til :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum':
    'Må være mindre enn det maksimale antallet',
  'Nom du texte': 'Navn på teksten',
  'Ajouter un rédacteur': 'Legg til en forfatter',
  Ou: 'Eller',
  Utilisateurs: 'Brukere',
  Heures: 'Timer',
  'Santé/Bien-être': 'Helse/velvære',
  'Interlingue ; Occidental': 'Flerspråklig; vestlig',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Latvisk',
  Anglais: 'engelsk',
  Envoyer: 'Send',
  'Tableau de bord': 'Dashbord',
  'Demande de révision': 'Anmodning om gjennomgang',
  'Résultat sémantique': 'Semantisk resultat',
  'Chercher un rédacteur': 'Søk etter en forfatter',
  'Ce champs est obligatoire': 'Dette feltet er obligatorisk',
  Rédaction: 'Lederartikkel',
  'Modifier le client': 'Endre klienten',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Vil du virkelig slette forfattere',
  Titre: 'Tittel',
  Langue: 'Språk',
  'Mot clé': 'Nøkkelord',
  'Les demandes suivantes ne seront pas prises en compte':
    'Følgende søknader vil ikke bli vurdert',
  'délai dépassé': 'oversittet frist',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Eksisterende kontrakt, vennligst endre informasjonen',
  Igbo: 'Igbo',
  Yiddish: 'Jiddisk',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgsk',
  'Commandes validées': 'Validerte bestillinger',
  'listeRedacteur-': 'listforfatter-',
  Étape: 'Trinn',
  'Energie/Environnement': 'Energi/miljø',
  Min: 'Min',
  Gujarati: 'Gujarati',
  'ne doit pas inclure votre nom/prénom': 'må ikke inneholde navn/fornavn',
  'Typix - Fiche rédacteur': 'Typix - Forfatterens fil',
  'révisions effectuées en attentes de validation':
    'revisjoner i påvente av validering',
  'Textes validés': 'Validerte tekster',
  Art: 'Kunst',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Allsidig',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Totalt antall tekster',
  'Nombre de mots gérés': 'Antall ord som forvaltes',
  "L'émail": 'Emalje',
  'Afficher les résultats': 'Vis resultater',
  'Frison occidental': 'Vestfrisisk',
  'Modification effectuée avec succès': 'Endringen er fullført',
  'Balise titre': 'Tittelkode',
  'Typix - Mon profil': 'Typix - Min profil',
  'email invalide': 'ugyldig e-post',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Legg til en nettadresse slik at du kan lagre teksten som et utkast.',
  Macédonien: 'Makedonsk',
  'Votre candidature a bien été envoyée': 'Søknaden din er sendt',
  'E-mail': 'E-post',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Legg til eller fullfør innholdet nedenfor',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Legge til nøkkelord med gjenværende frekvens',
  'Fiche du site web': 'Nettstedsark',
  Contacter: 'Kontakt',
  Fidjien: 'Fijiansk',
  Déconnexion: 'Frakobling',
  email: 'e-post',
  'Nombre de textes': 'Antall tekster',
  'Enregistrer et informer client': 'Registrering og informasjon til kunder',
  'Date de validation': 'Valideringsdato',
  'Tonga (Îles Tonga)': 'Tonga (Tongaøyene)',
  Javanais: 'javanesisk',
  Portugais: 'Portugisisk',
  Biélorusse: 'Hviterussisk',
  'Pour ajouter un nouveau texte': 'For å legge til en ny tekst',
  'Enfant/Puéri': 'Barn/Puperium',
  'Mots clés': 'Nøkkelord',
  Emploi: 'Sysselsetting',
  'Voulez-vous vraiment supprimer la commande de':
    'Ønsker du virkelig å fjerne',

  Consultants: 'Konsulenter',
  Volapük: 'Volapük',
  Services: 'Tjenester',
  'mot-clé (fréquence)': 'nøkkelord (frekvens)',
  'Ne plus afficher ce message': 'Ikke vis denne meldingen igjen',
  Consultant: 'Konsulent',
  "Une erreur s'est produite": 'Det har oppstått en feil',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Minst 8 tegn med en stor bokstav, et tall eller et spesialtegn.',
  'Pushto ; Pashto': 'Pushto; pashto',
  Français: 'Fransk',
  validées: 'validert',
  'Url du site': 'Nettstedets nettadresse',
  Précédent: 'Tidligere',
  'car celui-ci a déjà rédigé des textes':
    'fordi sistnevnte allerede har utarbeidet tekster',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Utkast',
  Ido: 'Ido',
  'Contenu disponible': 'Tilgjengelig innhold',
  Enseignement: 'Undervisning',
  'a été livré avec succès': 'har blitt levert med suksess',
  Chinois: 'Kinesisk',
  Vietnamien: 'Vietnamesisk',
  'Nouveau client': 'Ny kunde',
  'Votre profil a été mis à jour avec succès.':
    'Profilen din har blitt oppdatert.',
  Consignes: 'Instruksjoner',
  'Mot de passe': 'Passord',
  Annuler: 'Avbryt',
  Non: 'Nei',
  Nom: 'Navn',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'det er pålegg knyttet til nettstedet',
  Score: 'Resultat',
  'Mot de passe mis à jour avec succès': 'Passordet er oppdatert',
  Latin: 'Latin',
  Technologie: 'Teknologi',
  Internet: 'Internett',
  Nauru: 'Nauru',
  'a été créé avec succès': 'har blitt opprettet',
  Roumain: 'Rumensk',
  Assamais: 'assamesisk',
  'Khmer central': 'Sentral-Khmer',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Punjabi; Punjabi',
  'Analyse/Limite': 'Analyse/grense',
  Grec: 'Gresk',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Mine prosjekter',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'nøkkelord1 (2)\nnøkkelord2 (1)\nnøkkelord3 (1)\nnøkkelord4 (2)\n...',
  'ajouter un texte': 'legg til en tekst',
  'Détail de la prestation': 'Detaljer om tjenesten',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Du har mulighet til å eksportere rapporten for en eller flere bestillinger. For å eksportere alle ordrene til en kunde, trenger du bare å klikke på det gule nedlastingsikonet.',
  'Gestion des utilisateurs': 'Brukeradministrasjon',
  'livré et inférieur au nombre de texte non affecté':
    'levert og mindre enn antall ikke-tildelte tekster',
  'Date deadline validation client': 'Frist for kundevalidering',
  Aymara: 'Aymara',
  Validées: 'Validert',
  Notes: 'Merknader',
  'Nombre de GMB': 'Antall GMB-er',
  'résultats sur': 'resultater på',
  'Prénom / Nom du client': 'Fornavn / Navn på klienten',
  'Tout télécharger': 'Last ned alle',
  'Champs obligatoires': 'Påkrevde felter',
  Éditer: 'Rediger',
  'Coller le texte initial dans le champs ci-dessous':
    'Lim inn originalteksten i feltet nedenfor',
  'ID rédacteur': 'forfatter-ID',
  Arménien: 'Armensk',
  'Grande distribution': 'Distribusjon i stor skala',
  Welsh: 'Walisisk',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Semantisk berikelse',
  'Détail de la prestation du contenu': 'Detaljer om levering av innhold',
  Noir: 'Svart',
  'Ajouter un texte': 'Legg til en tekst',
  Traduction: 'Oversettelse',
  'Immo/Defisc': 'Eiendom/skatt',
  'Brief détaillé': 'Detaljert sammendrag',
  'Séparez les mots-clés par un Enter .': 'Separer nøkkelordene med Enter .',
  Prix: 'Utmerkelser',
  'mot de passe oublié': 'Glemt passord',
  'Ndebele, South ; South Ndebele': 'Ndebele, Sør; Sørlige Ndebele',
  'Demander une révision': 'Be om en gjennomgang',
  'Demander une repasse globale': 'Be om en omfattende gjennomgang',
  'Sotho, Southern': 'Sotho, sørlige',
  'Le titre de la commande existe déjà':
    'Tittelen på bestillingen finnes allerede',
  'Félicitations !': 'Gratulerer!',
  Kurde: 'Kurdisk',
  Adresse: 'Adresse',
  Espagnol: 'Spansk',
  Ndonga: 'Ndonga',
  'En retard': 'For sent',
  'a été ajouté avec succès': 'har blitt lagt til',
  Commandes: 'Bestillinger',
  'a été mis en brouillon': 'er utarbeidet',
  'Rédacteur attribué': 'Tildelt forfatter',
  Rechercher: 'Søk',
  'Nouvelle commande': 'Ny ordre',
  'Commandes non attribuées': 'Ikke tildelte ordrer',
  'Le texte a été mis en brouillon': 'Teksten er utarbeidet',
  'Contact edito': 'Kontakt edito',
  Langues: 'Språk',
  Orange: 'Oransje',
  'Nynorsk norvégien': 'Norsk nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmensk',
  'contenu repassé, en attente validation client':
    'stryket innhold, i påvente av kundevalidering',
  'Nombre de mots rédigés': 'Antall skrevne ord',
  Malay: 'Malaysisk',
  'Nombre de Silo': 'Antall Silo',
  Oui: 'Ja',
  Automobile: 'Biler',
  Résultats: 'Resultater',
  Afar: 'Langt unna',
  'Tâche - id tâche': 'Oppgave - oppgave-ID',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Dette feltet er obligatorisk og må være større enn 0.',
  'Vous êtes sûr': 'Er du sikker på at',
  'Réinitialiser votre mot de passe': 'Tilbakestill passordet ditt',
  Sindhi: 'Sindhi',
  Bengali: 'Bengali',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norsk; norsk bokmål',
  'Nombre de Jours': 'Antall dager',
  'Min/Max mots': 'Min/Maks ord',
  Slovène: 'Slovensk',
  Site: 'Nettsted',
  'Sinhala ; Cingalais': 'Singalesisk; singalesisk',
  Bulgare: 'Bulgarsk',
  'Liste des commandes': 'Liste over bestillinger',
  "L'utilisateur": 'Brukeren',
  Uzbek: 'Usbekisk',
  'Titre du texte': 'Tittel på teksten',
  Postuler: 'Søk på',
  'Nb de textes par Silo': 'Antall tekster per Silo',
  'Nombre total de mots (existants et ajoutés)':
    'Totalt antall ord (eksisterende og tilføyde)',
  'Max.': 'Maks.',
  Beauté: 'Skjønnhet',
  Humanitaire: 'Humanitær',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Zoeken op auteur of tekst',
  'Balise meta-description': 'Metabeskrivelseskode',
  Malgache: 'Madagaskisk',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Dette feltet må være større enn 0 og mindre enn 1.',
  'Le rédacteur': 'forfattere',
  'a été mis à jour avec succès': 'har blitt oppdatert',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Må være minst 8 tegn langt med en stor bokstav, et tall, et symbol og ikke identisk med navnet.',
  'textes en plus': 'flere tekster',
  'Format souhaité': 'Ønsket format',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detaljer om ordrelinjen',
  'Veuillez préciser la date': 'Vennligst spesifiser dato',
  Pali: 'Pali',
  Clients: 'Kunder',
  'Total facturé': 'Totalt fakturert',
  'contenu validé par le client': 'innhold validert av kunden',
  'Occitan (post 1500)': 'Okzitansk (etter 1500)',
  Japonais: 'Japansk',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Ønsker du virkelig å aktivere',
  Sanskrit: 'Sanskrit',
  'Toutes les notifications': 'Alle meldinger',
  Marathi: 'Marathi',
  Persan: 'Persisk',
  'La commande pour le client': 'Bestillingen for kunden',
  'Intitulé de la commande': 'Tittel på bestillingen',
  Samoan: 'Samoansk',
  Autres: 'Annet',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'det er pålegg knyttet til nettstedet',
  'Ajout de mots-clé avec la fréquence restante':
    'Legge til nøkkelord med gjenværende frekvens',
  'a été supprimé avec succès': 'har blitt fjernet',
  Postulé: 'Publisert',
  Literie: 'Sengetøy',
  'Édité par le client': 'Redigeres av kunden',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Tekster',
  'Soumettre un nouveau texte': 'Send inn en ny tekst',
  Téléphone: 'Telefon',
  Deadline: 'Tidsfrist',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Det har oppstått en feil. Vennligst sjekk oppføringen eller prøv igjen senere.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Forespørselen om å legge til kreditt sendes',
  'Le rédacteur ': 'forfattere',
  'Demander des crédits': 'Søke om kreditt',
  "Cette commande n'existe pas": 'Denne kommandoen finnes ikke',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitisk',
  Admins: 'Administratorer',
  'Mettez des mots-clés avec fréquence': 'Angi nøkkelord med frekvens',
  Notifications: 'Meldinger',
  'en retard': 'sent',
  "l'admin": 'administrasjonen',
  'Mail rédacteurs': 'E-postforfattere',
  Amharique: 'Amharisk',
  Societe: 'Virksomhet',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Antall tekster lagt til',
  Société: 'Virksomhet',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Teksten ble levert med suksess',
  'Mettez votre texte': 'Legg inn teksten din',
  "Oups....! Une erreur s'est produite": 'Oops....! Det har oppstått en feil',
  'Prénom/Nom': 'Fornavn/efternavn',
  'Max doit être sup à Min': 'Maks må være større enn Min',
  'Importer un autre document': 'Importer et annet dokument',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Søknaden din er utløpt ... vennligst lag en ny søknad',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Korriger formatet på det semantiske gapet for å lagre teksten som et utkast.',
  Exemple: 'Eksempel',
  Slovaque: 'Slovakisk',
  Finance: 'Finans',
  Réactiver: 'Reaktivere',
  'Nb textes': 'Antall tekster',
  'Intitulé commande': 'Bestillingstittel',
  Supprimer: 'Slett',
  Brouillon: 'Utkast',
  'Mots clés restants': 'Resterende nøkkelord',
  Date: 'Dato',
  Rôle: 'Rolle',
  'Ce champs doit être sup à 0': 'Dette feltet må være større enn 0',
  'Maison/Déco': 'Hjem/Innredning',
  'Vous avez': 'Du har',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Denne kommandolinjen kan ikke slettes fordi noe av teksten er levert.',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'sjekk e-posten din og klikk på lenken for å fortsette!',
  Sarde: 'Sardinia',
  'Note rédacteur': 'forfatteres merknad',
  'Uighur ; Uyghur': 'Uigurisk; uigurisk',
  Name: 'Navn',
  'Commandes intelligentes': 'Smart kommandoer',
  'Commandes avec révision': 'Smart + revision kommandoer',
  'Type de contenu': 'Indholdstype',
  'Fiche produit': 'Produktside',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium-redigering',
  'Rédaction avec révision': 'Smart + revision redigering',
  'Rédaction intelligente': 'Smart redigering',
  PREMIUM: 'PREMIUM',
  Type: 'Type',
  Editer: 'Rediger',
  'Tous les types': 'Alle typer',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',
  'Factures traitées': 'Behandlede fakturaer',
  'Factures à valider': 'Fakturaer som skal valideres',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Kan ikke generere fakturaen! Vennligst prøv igjen',
  'Récapitulatif de facturation': 'Oppsummering av fakturering',
  'Demander une modification': 'Be om en endring',
  'Valider et envoyer la facture': 'Validere og sende fakturaen',
  'Demande de modification': 'Endringsforespørsel',
  'La facture a été validé avec succès!':
    'Fakturaen er blitt validert med suksess!',
  "Oups un erreur c'est produit!": 'Oops, det oppstod en feil!',
  'Nombre de texte': 'Antall tekster',
  'Nombre de mot par texte :': 'Antall ord per tekst:',
  Paiement: 'Betaling',
  Virement: 'Overføring',
  Délai: 'Frist',
  'Référence de votre facture': 'Din fakturareferanse',
  'Coût au mot': 'Kostnad per ord',
  'Montant global de la commande': 'Totalt beløp for bestillingen',
  'Votre facture': 'Din faktura',
  'Importer votre facture': 'Importer din faktura',
  'Pas de fichier disponible': 'Ingen tilgjengelige filer',
  Télécharger: 'Last ned',
  Relancer: 'Sende på nytt',
  'Générer la facture': 'Generere fakturaen',
  Payée: 'Betalt',
  'En cours de paiement': 'Under betaling',
  'En cours de validation': 'Under validering',
  'A envoyer': 'Skal sendes',
  'Modification demandée': 'Endring forespurt',
  'Référence de facture est obligatoire': 'Fakturareferanse er obligatorisk',
  'Le coût au mot doit être un nombre': 'Kostnaden per ord må være et tall',
  'Le coût au mot doit être un nombre positif':
    'Kostnaden per ord må være et positivt tall',
  'Le coût au mot est obligatoire': 'Kostnaden per ord er obligatorisk',
  'Le montant global de la commande doit être un nombre':
    'Totalbeløpet for bestillingen må være et tall',
  'Le montant global de la commande doit être un nombre positif':
    'Totalbeløpet for bestillingen må være et positivt tall',
  'Le montant global de la commande est obligatoire':
    'Totalbeløpet for bestillingen er obligatorisk',
  'Bill reference existe déja , veuillez réessayer':
    'Fakturareferansen eksisterer allerede, vennligst prøv igjen',
  'La facture a été envoyée avec succès!':
    'Fakturaen er blitt sendt med suksess!',

  'Montant estimé': 'Estimert beløp',
  Action: 'Handling',
  Facture: 'Faktura',
  Statut: 'Status',
  Factures: 'Fakturaer',
  'Valider la facture': 'Godkjenn fakturaen',
  'La relance a été envoyée avec succès !': 'Purringen er sendt med suksess!',
  'La demande de modification a été envoyée avec succès !':
    'Endringsforespørselen er sendt med suksess!',
  'Le cout est trop élevé': 'Kostnaden er for høy',
  'Le montant global de la commande est trop élevé ':
    'Den totale summen av bestillingen er for høy',
  jours: 'dager',
  'Factures à envoyer': 'Fakturaer å sende',
  'Valider le paiement': 'Bekreft betaling',
  'Générer une facture': 'Generer en faktura',
  'Montant rédacteur': 'Forfatterbeløp',
  'Montant estimé par Typix': 'Estimert beløp av Typix',
  'Voir la modification': 'Vis endringen',

  'Tag de Titre': 'Tittel-tag',
  'Meta description': 'Meta-beskrivelse',
  'Nom du produit': 'Produktnavn',
  Avantages: 'Fordeler',
  'Description détaillée': 'Detaljert beskrivelse',
  'En cours de modification': 'Under endring',
  'Mes Factures': 'Mine fakturaer',
  'En attente de validation': 'Venter på validering',
  'les valeurs négatives ne sont pas autorisées':
    'Negative verdier er ikke tillatt.',
  'Texte Seulement': 'Bare tekst',
  'Traduction Seulement': 'Bare oversettelse',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Purring kan sendes 20 dager etter fakturainnsending',
  Instructions: 'Instruksjoner',
  'Votre avis pour la commande': 'Din tilbakemelding for bestillingen',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Din evaluering er verdifull for oss! Takk!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Det oppsto en feil under innsendingen av din evaluering. Vennligst prøv igjen senere!',
  Evaluer: 'Vurdere',
  Commentaire: 'Kommentar',
  'Votre commantaire': 'Din kommentar',
  Note: 'Vurdering',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Din tilbakemelding er verdifull for oss og hjelper oss med å forbedre våre tjenester.',
  'Verifier la facture': 'Verifiser fakturaen',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Din tilbakemelding er verdifull for oss og hjelper oss med å forbedre våre tjenester. Takk for at du deler din evaluering med oss!',
  'Notes rédacteur': 'Forfatternotater',
  'Avis client': 'Kundeanmeldelser',
  'Pas de commantaire': 'Ingen kommentar',
  "Qu'avez vous pensé de votre commande?": 'Hva synes du om bestillingen din?',
  'Evaluation de la commande': 'Vurdering av bestillingen',
  'Rédiger un commentaire': 'Skriv en kommentar',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Bidra til at vi kan tilby deg tjenester av høyeste kvalitet ved å dele dine tilbakemeldinger.',
  'Que pouvons-nous améliorer?': 'Hva kan vi forbedre?',
  Continuer: 'Fortsett',
  'Le dernier texte a été validé avec succès':
    'Den siste teksten ble validering med suksess',
  'Rechercher par rédacteur, commandes, et montant':
    'Cerca per autore, ordini e importo',
  obligatoire: 'obligatorisk',
  'Note admin': 'Merknad fra administrator',
  'Note client': 'Merknad fra kunde',
  Partie: 'Del',
  'Vous venez de valider tous les contenus rédigés par ':
    'Du har nettopp godkjent all innhold skrevet av ',
  "Qu'en avez vous pensé": 'Hva synes du om det',
  'La facture a été générée avec succès !':
    'Fakturaen er generert med suksess!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Lengden på tittelen og/eller meta-beskrivelsen er for kort',

  'Demande de révision globale': 'Krever en global gjennomgang',
  Valider: 'Godkjenne',
  'Obligatoire en cas d’optimisation ou traduction':
    'Påkrevet ved optimalisering eller oversettelse',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Din kommentar...',
  'Ex : "Contenus de très bonne qualité':
    'Eks.: "Meget god kvalitet på innholdet"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Kan du vennligst bruke denne skribenten til fremtidige bestillinger?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Denne knappen er for å informere klienten om at den opprinnelige teksten allerede inneholdt nøkkelordfyll',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Rediger teksten',
  Ajouter: 'Legg til',
  'Demande examen': 'Be om eksamen',
  'Recherche de mots clés à haute densité': 'Keyword stuffing forskning',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, det ser ut til at det er keyword stuffing i teksten din: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, det ser ut til at det er keyword stuffing (for mange forekomster av ett eller flere nøkkelord) i den opprinnelige teksten.',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Vennligst sørg for at nøkkelordtettheten er optimalisert for SEO (maksimalt én forekomst for hver 50 ord).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'For dette, vennligst ikke rediger den eksisterende teksten og send en undersøkelsesforespørsel til støtte.',
  'Votre demande a été bien envoyée !': 'Ditt forespørsel har blitt sendt!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Det oppsto et problem under sendingen av forespørselen din.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Vennligst vent på analysen av nøkkelordfylling.',
  'Inserez le maillage interne': 'Sett inn intern kobling',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Vennligst sørg for at nøkkelordtettheten er optimalisert for SEO (maksimalt én forekomst hver 50. ord).\nHvis du kommer over en term som kan ha flere betydninger (Eksempel: Former / Formation / Form eller Matelas / Matelassé), kan du be om en gjennomgang fra support.',
  'Mots clés à haute densité dans le texte actuel':
    'Nøkkelord med høy tetthet i gjeldende tekst',
  'Mots clés à haute densité dans le texte initial':
    'Nøkkelord med høy tetthet i opprinnelig tekst',
  'Texte en cours de rédaction !': 'Tekst under utarbeidelse !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Er du sikker på at du vil bekrefte teksten? Forsikre deg først om at du har lagret endringene.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Siste øyeblikks informasjon! Kredittbeholdningen din vil snart være tom :( For å være føre var, nøl ikke med å be om mer kreditt nå for å fullføre bestillingen din.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Ønsker du å bekrefte den nåværende URL-en eller endre den?',
  'Veuillez fournir une URL valide': 'Vennligst oppgi en gyldig URL.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Vennligst oppgi URL-en til siden der innholdet er integrert nedenfor:',
  'Contenu en ligne': 'Nettinnhold',
  'Télécharger le document de révision': 'Last ned revisjonsdokumentet',
  'Date d’envoi': 'Sendingsdato',
  Information: 'Informasjon',
  Enregistrer: 'Lagre',
  'Textes en ligne': 'Tekster på nett',
  'Livrer les textes': 'Lever tekstene',
  Indisponible: 'Utilgjengelig',
  Disponible: 'Tilgjengelig',
  'Les textes ont été livrés avec succès.': 'Tekstene ble levert med suksess.',
  'Le texte a été livré avec succès.': 'Teksten ble levert med suksess.',
  'Les textes ont été validés avec succès.':
    'Tekstene ble validert med suksess.',
  'Le texte a été validé avec succès.': 'Teksten ble validert med suksess.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Vil du virkelig godkjenne bestillingstekstene?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Vil du virkelig levere bestillingstekstene?',
  'Fiche produit longue': 'Lang produktside',
  'Fiche produit courte': 'Kort produktside',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'En tekst er for lang og kan ikke eksporteres til Excel.',
};
