export const chekRepublicTranslation = {
  'Les lignes de commandes ont été mises à jour avec succès !':
    'Příkazové řádky byly úspěšně aktualizovány!',
  'Le texte a été enregistré avec succès': 'Text byl úspěšně zaregistrován',
  'La traduction a été enregistrée avec succès': 'Překlad byl úspěšně uložen',
  'Texte + Traduction enregistré avec succès':
    'Text + Překlad byl úspěšně uložen',
  'Nombre de mots': 'Počet slov',
  Lingala: 'Lingala',
  'Prénom/Nom du client': 'Jméno/příjmení klienta',
  Bashkir: 'Baškirština',
  "La date doit être ultérieure à aujourd'hui.":
    'Datum musí být pozdější než dnes.',
  'Contenu rédigé': 'Napsaný obsah',
  'Nom/prénom': 'Příjmení/jméno',
  'Mot de passe incorrect': 'Nesprávné heslo',
  Institutions: 'Instituce',
  'Veuillez donner un descriptif au document téléchargé':
    'Uveďte prosím popis staženého dokumentu',
  'Sichuan Yi ; Nuosu': 'Sichuan Yi; Nuosu',
  Suivant: 'Další',
  Kannada: 'Kannada',
  'Séparer les mots clés par un "enter"':
    'Klíčová slova oddělujte klávesou "enter".',
  Allemand: 'Němčina',
  Sango: 'Sango',
  'Min.': 'Min.',
  'un email contenant un lien de réinitialisation du mot de passe a été envoyé à votre email':
    'na váš e-mail byl zaslán e-mail s odkazem na obnovení hesla.',
  'Chef edito': 'Šéfredaktor',
  Croate: 'Chorvatský',
  'Nb de textes': 'Počet textů',
  Violet: 'Violet',
  'Ce champs doit être sup ou égale nombre de texte':
    'Toto pole musí být větší nebo rovno počtu textů.',
  'a été activé avec succès': 'byl úspěšně aktivován',
  Népalais: 'Nepál',
  'Nombre de FAQ': 'Počet často kladených otázek',
  Urdu: 'Urdu',
  'Analyses restantes': 'Zbývající analýzy',
  Indonésien: 'Indonéský',
  Breton: 'Breton',
  Livrés: 'Doručeno na',
  Associations: 'Asociace',
  'Le client': 'Klient',
  'Révision demandée': 'Vyžádaná recenze',
  "Une erreur s'est produite, veuillez réessayer":
    'Došlo k chybě, zkuste to prosím znovu',
  'Mise à jour non autorisée car des textes ont été livrés':
    'Neautorizovaná aktualizace, protože texty byly doručeny',
  "Pour ajouter un texte (optimisation d'un contenu existant)":
    'Přidání textu (optimalizace stávajícího obsahu)',
  Gaélique: 'Gaelština',
  'La ligne de commande pour': 'Příkazový řádek pro',
  'Business/Management': 'Podnikání/řízení',
  Serbe: 'Srbština',
  "Merci d'indiquer ci-dessous vos demandes de modifications en prenant bien en compte nos conditions de repasses":
    'Níže prosím uveďte své požadavky na úpravy s ohledem na naše podmínky žehlení.',
  'rédacteurs trouvés': 'nalezených autoři',
  'Kirghiz ; Kirghiz': 'Kirgizové; Kirgiz',
  Différence: 'Rozdíl',
  'en cours': 'probíhá',
  'Sami du Nord': 'Severní Laponci',
  Comparer: 'Porovnat',
  Lituanien: 'Litevština',
  'Typix - Mon tableau de bord': 'Typix - Můj přístrojový panel',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial et ne pas être identique au nom':
    'Musí obsahovat alespoň 8 znaků, velké písmeno, číslici, speciální znak a nesmí být totožný se jménem.',
  Webmaster: 'Webmaster',
  'Veuillez saisir des champs valides': 'Zadejte prosím platná pole',
  Basque: 'Baskicko',
  'Exporter .csv': 'Export .csv',
  'Confirmer le nouveau mot de passe': 'Potvrzení nového hesla',
  "Pas d'options": 'Žádné možnosti',
  'Banque/Assurance': 'Bankovnictví/pojišťovnictví',
  'Vos demandes': 'Vaše požadavky',
  'Coût d\u2019achat': 'Nákupní náklady',
  Tamil: 'Tamil',
  'Le texte a été supprimé avec succès': 'Text byl úspěšně odstraněn',
  'Mes Commandes': 'Moje objednávky',
  Zoulou: 'Zulu',
  Afficher: 'Zobrazit',
  Texte: 'Text',
  Islandais: 'Islandské',
  Russe: 'Ruský',
  'Tous les textes': 'Všechny texty',
  Czech: 'Česky',
  'Comparaison hors ligne': 'Srovnání offline',
  Téléphonie: 'Telefonování',
  'Valider le texte': 'Ověření textu',
  'Votre text ne de doit pas dépasser 500 mots':
    'Váš text by neměl přesáhnout 500 slov',
  'Coquilles (double espace, « s »\u2026)': 'Skořápky (dvojitá mezera, "s"...)',
  'le consultant': 'konzultant',
  Coréen: 'Korejský',
  'Nb de Silo': 'Počet sil',
  Validés: 'Ověřeno',
  'non attribuées': 'nepřidělené',
  Néerlandais: 'Holandský',
  'Voulez-vous vraiment supprimer': 'Opravdu chcete odstranit',
  Rundi: 'Rundi',
  Regional: 'Regionální',
  'Compte client': 'Účet zákazníka',
  Global: 'Globální',
  'Tous les rôles': 'Všechny role',
  Musique: 'Hudba',
  Perso: 'Osobní',
  Kinyarwanda: 'Kinyarwanda',
  'Il semblerait que tu n\u2019aies plus de crédit d\u2019analyse':
    'Zdá se, že už nemáte žádný analytický kredit',
  Bambara: 'Bambara',
  Sortir: 'Vystupte',
  optionel: 'volitelné',
  'Temps restant': 'Zbývající čas',
  Ukrainien: 'Ukrajinský',
  'Chichewa ; Chewa ; Nyanja': 'Chichewa; Chewa; Nyanja',
  'Votre demande de révision a bien été envoyée.':
    'Vaše žádost o přezkoumání byla odeslána.',
  'Typix - Créer rédacteur': 'Typix - Vytvoření autoru',
  "n'existe pas": 'neexistuje',
  Hindi: 'Hindština',
  'ne peut pas être supprimé car il est lié à des sites web':
    'nelze smazat, protože je propojen s webovými stránkami',
  'Commandes en cours': 'Probíhající objednávky',
  'Textes livrés': 'Doručené texty',
  Validation: 'Ověřování',
  'Non attribuées': 'Nepřiděleno',
  'Voulez-vous vraiment désactiver': 'Opravdu chcete deaktivovat',
  'Catalan ; Valencien': 'katalánština; valencijština',
  'Nombre de textes restants': 'Počet zbývajících textů',
  'Ne doit pas contenir votre nom': 'Nesmí obsahovat vaše jméno',
  Documentation: 'Dokumentace',
  Kazakh: 'Kazachstán',
  'Numéro invalide': 'Neplatné číslo',
  'Veuillez saisir un texte ou importer un fichier.':
    'Zadejte text nebo importujte soubor.',
  'Nb de FAQ': 'Počet často kladených otázek',
  Analyser: 'Analýza',
  Turc: 'Turecký',
  'Toute demande n\u2019ayant pas été précisée lors du brief':
    'Veškeré požadavky, které nejsou uvedeny ve stručném popisu',
  Wallon: 'Valonsko',
  Birmane: 'Barmský',
  Irlandais: 'Irish',
  Oriya: 'Oriya',
  'Réinitialiservotre mot de passe': 'Obnovení hesla',
  Cuisine: 'Kuchyně',
  'Haïtien ; créole haïtien': 'haitština; haitská kreolština',
  'Vous ne pouvez pas supprimer le rédacteur': 'autor nelze odstranit',
  Validé: 'Ověřeno',
  "cet email n'existe pas": 'tento e-mail neexistuje',
  Historique: 'Historie',
  Confirmer: 'Potvrďte',
  'à cocher si le nombre de mots est différent par texte':
    'zaškrtnout, pokud se počet slov v textu liší.',
  'Fiche du site web ': 'Webový list',
  Désactiver: 'Deaktivace',
  'Travaux/BTP': 'Práce/stavební práce',
  'La fiche du website': 'Webový list',
  'Si le contenu ne respecte pas les règles du brief':
    'Pokud obsah nerespektuje pravidla zadání',
  Norvégien: 'Norština',
  '0 rédacteurs': '0 autoři',
  indisponible: 'nedostupné',
  Venda: 'Venda',
  Maori: 'Maori',
  'Textes revisés': 'Revidované texty',
  'Status texte': 'Stav textu',
  'textes livrés en attente de validation':
    'doručené texty čekající na ověření',
  'Typix - Mon Profile': 'Typix - Můj profil',
  'le client': 'klient',
  Romanche: 'Romanche',
  'URL du site': 'Adresa URL webu',
  'nouvelles notifications': 'nová oznámení',
  'Divehi ; Dhivehi ; Maldivien': 'Divehi; Dhivehi; Maledivy',
  'Êtes vous sur de vouloir sauvegarder le texte':
    'Jste si jisti, že chcete text uložit',
  'Veuillez ajouter un texte initiale afin de pouvoir sauvegarder le texte en brouillon':
    'Přidejte prosím počáteční text, abyste mohli text uložit jako návrh.',
  Xhosa: 'Xhosa',
  'Le contrat pour le client': 'Smlouva pro zákazníka',
  rédacteurs: 'autoři',
  'Ce champ est obligatoire': 'Toto pole je povinné',
  'Tarif/Mot': 'Sazba/slovo',
  Tadjik: 'Tádžik',
  'Réf devis': 'Odkaz na citaci',
  'Rechercher un rédacteur': 'Hledání autor',
  Albanais: 'Albánština',
  Galicien: 'Galicijský',
  Disponibilité: 'Dostupnost',
  'Les deux mots de passe ne sonts pas identiques': 'Obě hesla nejsou totožná',
  Tibétain: 'Tibeťan',
  'Typix - Gestion des utilisateurs': 'Typix - Správa uživatelů',
  'Ossétien ; Ossétique': 'Osetština; osetština',
  Tswana: 'Tswana',
  'Ajouter un client': 'Přidání zákazníka',
  'Séparez les mots-clés par un " Enter ".':
    'Klíčová slova oddělte klávesou "Enter".',
  'Zhuang ; Chuang': 'Zhuang; Chuang',
  Thématiques: 'Témata',
  'Nb mots/texte': 'Počet slov/textů',
  Commerce: 'Obchod',
  'Voir les commandes': 'Viz objednávky',
  'Nombre de mots par texte': 'Počet slov v textu',
  'Lien drive...': 'Odkaz pohon...',
  'Hollandais ; Flamand': 'nizozemština; vlámština',
  'Mon profil': 'Můj profil',
  "Ce champs doit être sup à 0 car facturation n'est pas au réel":
    'Toto pole musí být větší než 0, protože fakturace není skutečná.',
  'Le texte a été validé avec succès': 'Text byl úspěšně ověřen',
  Jeux: 'Hry',
  'Voulez-vous vraiment supprimer la ligne de commande pour':
    'Opravdu chcete odstranit příkazový řádek pro',
  'Ndebele, Nord ; Ndebele Nord': 'Ndebele, Sever; Ndebele North',
  'Slave ecclésiastique ; vieux slavon ; slavon ecclésiastique ; vieux bulgare ; vieux slavon ecclésiastique':
    'církevní slovanština; staroslověnština; církevní slovanština; stará bulharština; stará církevní slovanština',
  Swahili: 'Svahilština',
  Voyage: 'Cestování',
  'Nombre de mot à ajouter': 'Počet slov, která se mají přidat',
  'Liste des rédacteurs': 'Seznam autoři',
  'le rédacteur': 'autor',
  Kanuri: 'Kanuri',
  rédacteur: 'autor',
  Tatar: 'Tatar',
  'Roumain ; Moldave ; Moldovan': 'rumunština; moldavština',
  'Le texte initial de': 'Původní text',
  'Mail clients': 'Zákaznická pošta',
  'Le contrat de': 'Smlouva o',
  'au moins une lettre minuscule et une lettre majuscule,un caractère spécial et un chiffre':
    'alespoň jedno malé a jedno velké písmeno, jeden speciální znak a jednu číslici.',
  Transport: 'Doprava',
  'Nombre total de mots (existants et ajoutés) :':
    'Celkový počet slov (stávajících a přidaných) :',
  'Rechercher par mail, prénom, nom, site...':
    'Vyhledávání podle e-mailu, jména, příjmení, webu...',
  'Nombre de mot à ajouter (Min/Max) : ':
    'Počet slov, která mají být přidána (Min/Max) :',
  Compte: 'Účet',
  'Tourisme/Loisirs': 'Cestovní ruch/volný čas',
  'Nouveau texte': 'Nový text',
  Max: 'Max',
  'Kalaallisut ; Groenlandais': 'Kalaallisut; grónština',
  'Oups...!il y a une/plusieurs erreur(s) dans la fenêtre du gap sémantique. Veuillez vérifier son contenu pour la/les corriger et soumettre à nouveau votre texte.':
    'Ups...! V okně sémantické mezery je jedna nebo více chyb. Zkontrolujte prosím jeho obsah, opravte jej/je a znovu odešlete svůj text.',
  'ne peut pas être supprimé pour le moment': 'nelze v tuto chvíli vymazat',
  'Le texte': 'Text',
  URL: 'ADRESA URL',
  "Un utilisateur existe déjà avec l'email":
    'Uživatel s tímto e-mailem již existuje',
  Cree: 'Cree',
  Prénom: 'Křestní jméno',
  'commandes prête': 'připravené objednávky',
  Réinitialiser: 'Obnovení',
  'Veuillez fournir une date valide': 'Uveďte prosím platné datum',
  'Kuanyama ; Kwanyama': 'Kuanyama; Kwanyama',
  'Luxembourgeois ; Letzeburgesch': 'Lucemburština; Letzeburgesch',
  Suédois: 'Švédský',
  'Confirmer le mot de passe': 'Potvrzení hesla',
  'Ce champs est obligatoire et ne peut avoir que des nombres':
    'Toto pole je povinné a může obsahovat pouze čísla',
  Sciences: 'Věda',
  Tchétchène: 'Čečensko',
  "est le seul client pour un site, il faut ajouter d'autres pour pouvoir le supprimer":
    'je jediným klientem webu, pro jeho odstranění je třeba přidat další.',
  'Le mot de passe de confirmation ne correspond pas':
    'Potvrzovací heslo se neshoduje',
  Swati: 'Swati',
  Danois: 'Dánský',
  Sauvegarder: 'Uložit',
  'Veuillez changer les informations suivantes titre et/ou mot clé':
    'Změňte prosím následující informace: název a/nebo klíčové slovo',
  'importer un document': 'importovat dokument',
  Maltais: 'Maltský',
  'Nb de mots': 'Počet slov',
  'Les données fournies sont invalides': 'Poskytnuté údaje jsou neplatné',
  'Livraison estimée': 'Odhadované dodání',
  'Grec moderne (1453-)': 'Moderní řečtina (1453-)',
  Avestan: 'Avestánština',
  Inuktitut: 'Inuktitut',
  'Nombre total de mots ajoutés': 'Celkový počet přidaných slov',
  'le contenu présente des inexactitudes': 'obsah obsahuje nepřesnosti',
  Finnois: 'Finsko',
  'Ajouter un admin': 'Přidání správce',
  'Min/Max': 'Min/Max',
  'Modifier le consultant': 'Úprava konzultanta',
  'Liste des clients': 'Seznam klientů',
  'Nom client': 'Jméno zákazníka',
  Vert: 'Zelená',
  'Mots clés manquants': 'Chybějící klíčová slova',
  Lao: 'Lao',
  Somali: 'Somálsko',
  'Nb de silo par texte': 'Počet sil na text',
  Italien: 'Italský',
  'La commande a été mis à jour avec succès !':
    'Objednávka byla úspěšně aktualizována!',
  Bosniaque: 'Bosenský',
  'Thématiques d\u2019expertise': 'Témata odborných znalostí',
  'Ce mot de passe est le mot de passe que vous avez oublié':
    'Toto je heslo, které jste zapomněli',
  'a été désactivé avec succès': 'byl úspěšně deaktivován',
  Kongo: 'Kongo',
  'Postuler à la commande': 'Požádat o objednávku',
  'mot-clé': 'klíčové slovo',
  Abkhazien: 'Abcházie',
  Mongolien: 'Mongolský',
  'Typix - Fiche site web': 'Typix - Webové stránky',
  'Mail envoyé': 'Odeslaná pošta',
  Géorgien: 'Gruzínština',
  'Si des phrases ne sont pas dans un français correct':
    'Pokud věty nejsou ve správné francouzštině',
  'En cours': 'Probíhá',
  voir: 'viz',
  "Date d'ajout": 'Datum přidání',
  'Tarif au mot': 'Sazba za slovo',
  Thai: 'Thai',
  'Détails du texte': 'Podrobnosti o textu',
  'Commandes en retard': 'Pozdní objednávky',
  disponible: 'k dispozici na',
  Droit: 'Právo',
  Azerbaïdjanais: 'Ázerbájdžán',
  'Ajouter un consultant': 'Přidání konzultanta',
  'Dans le cadre d\u2019une repasse sur des contenus, seuls les éléments suivants seront pris en charge':
    'Při přehrávání obsahu budou podporovány pouze následující položky.',
  'doit comprendre au moins 8 charactères': 'musí obsahovat alespoň 8 znaků',
  'Titre de la commande': 'Název zakázky',
  'Langues bihari': 'Bihárské jazyky',
  'Livraison estimé au': 'Předpokládané dodání do',
  "Le nombre de texte de la commande ne peut pas être inférieur aux nombre de textes attribuées au(x) rédacteur(s). Veuillez d'abord modifier les informations de la ligne de commande":
    'Počet textů v objednávce nesmí být menší než počet textů přidělených autorovi (autorům). Nejprve změňte informace v příkazovém řádku',
  'Date de livraison': 'Datum dodání',
  'Voulez-vous vraiment supprimer le contrat de': 'Opravdu chcete zrušit',
  'Facturation au réel': 'Skutečné vyúčtování',
  'Gap sémantique': 'Sémantická mezera',
  Estonien: 'Estonština',
  Wolof: 'Wolof',
  'Coût d\u2019achat max/mot': 'Maximální pořizovací cena/slovo',
  'Détail de la commande': 'Podrobnosti o objednávce',
  'Export en cours de téléchargement\u2026': 'Nahrávání exportu...',
  'Ce champ est obligatoire et doit être inférieur ou égal au Nombre total des textesde la commande':
    'Toto pole je povinné a musí být menší nebo rovno celkovému počtu textů v objednávce.',
  Arabe: 'Arabština',
  Bislama: 'Bislama',
  'Mail client': 'Zákaznická pošta',
  '(Titre, mot clé, url, balise, méta-description)':
    '(Title, keyword, url, tag, meta-description)',
  'Typix - Nouvelle commande': 'Typix - Nová objednávka',
  'Révision en attente': 'Recenze probíhá',
  'Le consultant': 'Konzultant',
  Entreprise: 'Společnost',
  Aragonais: 'Aragonský',
  Kashmiri: 'Kašmír',
  'a été activé': 'byl aktivován',
  'Interlingua (Association internationale des langues auxiliaires)':
    'Interlingua (Mezinárodní asociace pomocných jazyků)',
  Actualités: 'Zprávy',
  'Votre décision a été prise en compte avec succès':
    'Vaše rozhodnutí bylo úspěšně zohledněno',
  Afrikaans: 'Afrikánština',
  'contenu en attente de vérification client':
    'obsah čeká na ověření zákazníkem',
  'Fiche du rédacteur': 'Soubor autora',
  Outillage: 'Nástroje',
  'Typix - Détails de la commande': 'Typix - Order details',
  'Ajouter ou compléter ci-dessous votre contenu :':
    'Níže přidejte nebo doplňte svůj obsah:',
  Ewe: 'Ewe',
  'contenu en attente de repasse rédacteur':
    'autor přehrávání obsahu v očekávání',
  'La commande a été supprimé avec succès': 'Objednávka byla úspěšně smazána',
  'Expressions ou phrases françaises mais ne vous convenant pas':
    'Francouzské výrazy nebo fráze, které vám nevyhovují',
  Corse: 'Korsika',
  Modifier: 'Upravit',
  'Nb de GMB': 'Počet GMB',
  Oromo: 'Oromo',
  Polish: 'Polský',
  'Insérez votre gap sémantique avec l’occurrence ainsi que le maillage interne':
    'Vložte svou sémantickou mezeru s výskytem',
  'Nb de textes à optimiser': 'Počet textů k optimalizaci',
  Mode: 'Režim',
  Akan: 'Akan',
  'Révisions effectués': 'Provedené revize',
  'Modifier l\u2019admin': 'Upravit správce',
  'Télécharger le document': 'Stáhnout dokument',
  Hausa: 'Hausa',
  'Notes rédacteurs': 'Poznámky autoři',
  Quechua: 'Quechua',
  Féroïen: 'Faerské ostrovy',
  'Modifier le rédacteur': 'Změna autoru',
  Mail: 'Mail',
  Rédacteurs: 'autoři',
  'Typix - Détails du texte': 'Typix - Podrobnosti o textu',
  Hongrois: 'Maďarština',
  'Bon pour livraison': 'Dobré pro doručení',
  'Au réel': 'V reálném světě',
  'Hiri Motu': 'Hiri Motu',
  Hébreu: 'Hebrejština',
  'Nombre total de mots ajoutés :': 'Celkový počet přidaných slov :',
  Komi: 'Komi',
  'Doit être inférieur au nombre maximum': 'Musí být menší než maximální počet',
  'Nom du texte': 'Název textu',
  'Ajouter un rédacteur': 'Přidání autoru',
  Ou: 'Nebo',
  Utilisateurs: 'Uživatelé',
  Heures: 'Hodiny',
  'Santé/Bien-être': 'Zdraví/pohoda',
  'Interlingue ; Occidental': 'Mezijazykové; západní',
  'Luba-Katanga': 'Luba-Katanga',
  Letton: 'Lotyšský',
  Anglais: 'Angličtina',
  Envoyer: 'Odeslat',
  'Tableau de bord': 'Přístrojová deska',
  'Demande de révision': 'Žádost o přezkum',
  'Résultat sémantique': 'Sémantický výsledek',
  'Chercher un rédacteur': 'Hledání autora',
  'Ce champs est obligatoire': 'Toto pole je povinné',
  Rédaction: 'Redakce',
  'Modifier le client': 'Úprava klienta',
  'Voulez-vous vraiment supprimer le rédacteur':
    'Opravdu chcete odstranit autor',
  Titre: 'Název',
  Langue: 'Jazyk',
  'Les demandes suivantes ne seront pas prises en compte':
    'Následující žádosti nebudou posuzovány',
  'délai dépassé': 'promeškaná lhůta',
  Tchouvache: 'Tchouvache',
  Ganda: 'Ganda',
  Twi: 'Twi',
  'Contrat déjà existant, veuillez modifier les informations':
    'Stávající smlouva, upravte prosím informace',
  Igbo: 'Igbo',
  Yiddish: 'Jidiš',
  'Limburgan ; Limburger ; Limburgish': 'Limburgan; Limburger; Limburgish',
  'Commandes validées': 'Ověřené objednávky',
  'listeRedacteur-': 'listRedactor-',
  Étape: 'Krok',
  'Energie/Environnement': 'Energie/životní prostředí',
  Min: 'Min',
  Gujarati: 'Gudžarátština',
  'ne doit pas inclure votre nom/prénom': 'nesmí obsahovat vaše jméno/jméno',
  'Typix - Fiche rédacteur': 'Typix - Soubor autor',
  'révisions effectuées en attentes de validation':
    'provedené revize čekající na ověření',
  'Textes validés': 'Ověřené texty',
  Art: 'Art',
  Dzongkha: 'Dzongkha',
  Polyvalent: 'Všestranný',
  Sundanese: 'Sundanese',
  'Nb total de textes': 'Celkový počet textů',
  'Nombre de mots gérés': 'Počet spravovaných slov',
  "L'émail": 'Smalt',
  'Afficher les résultats': 'Zobrazit výsledky',
  'Frison occidental': 'západofríština',
  'Modification effectuée avec succès': 'Úprava byla úspěšně dokončena',
  'Balise titre': 'Značka Title',
  'Typix - Mon profil': 'Typix - Můj profil',
  'email invalide': 'neplatný e-mail',
  'Veuillez ajouter une url afin de pouvoir sauvegarder le texte en brouillon':
    'Přidejte prosím url adresu, abyste mohli text uložit jako návrh.',
  Macédonien: 'Makedonština',
  'Votre candidature a bien été envoyée': 'Vaše žádost byla odeslána',
  'E-mail': 'E-mail',
  Fulah: 'Fulah',
  'Ajouter ou compléter ci-dessous votre contenu':
    'Přidejte nebo doplňte svůj obsah níže',
  Manx: 'Manx',
  'Ajout de mots-clés avec la fréquence restante':
    'Přidání klíčových slov se zbývající frekvencí',
  'Fiche du site web': 'Webový list',
  Contacter: 'Kontakt',
  Fidjien: 'Fidži',
  Déconnexion: 'Odpojení',
  email: 'e-mail',
  'Nombre de textes': 'Počet textů',
  'Enregistrer et informer client': 'Registrace a informování zákazníků',
  'Date de validation': 'Datum ověření',
  'Tonga (Îles Tonga)': 'Tonga (Tongské ostrovy)',
  Javanais: 'Javánština',
  Portugais: 'Portugalština',
  Biélorusse: 'Běloruský',
  'Pour ajouter un nouveau texte': 'Přidání nového textu',
  'Enfant/Puéri': 'Dítě/Pupium',
  'Mots clés': 'Klíčová slova',
  Emploi: 'Zaměstnání',
  'Voulez-vous vraiment supprimer la commande de': 'Opravdu chcete odstranit',
  'Mot clé': 'Klíčové slovo',
  Consultants: 'Poradci',
  Volapük: 'Volapük',
  Services: 'Služby',
  'mot-clé (fréquence)': 'klíčové slovo (frekvence)',
  'Ne plus afficher ce message': 'Tuto zprávu již nezobrazujte',
  Consultant: 'Konzultant',
  "Une erreur s'est produite": 'Došlo k chybě',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un charactère spécial':
    'Alespoň 8 znaků s velkým písmenem, číslicí, speciálním znakem.',
  'Pushto ; Pashto': 'Puštunština; paštunština',
  Français: 'Francouzština',
  validées: 'ověřeno',
  'Url du site': 'Url stránky',
  Précédent: 'Předchozí',
  'car celui-ci a déjà rédigé des textes': 'protože ten již vypracoval texty',
  'Kikuyu ; Gikuyu': 'Kikuyu; Gikuyu',
  Brouillons: 'Návrhy',
  Ido: 'Ido',
  'Contenu disponible': 'Dostupný obsah',
  Enseignement: 'Výuka',
  'a été livré avec succès': 'byla úspěšně doručena',
  Chinois: 'Čínský',
  Vietnamien: 'Vietnamci',
  'Nouveau client': 'Nový zákazník',
  'Votre profil a été mis à jour avec succès.':
    'Váš profil byl úspěšně aktualizován.',
  Consignes: 'Pokyny',
  'Mot de passe': 'Heslo',
  Annuler: 'Zrušit',
  Non: 'Ne',
  Nom: 'Název',
  Guarani: 'Guarani',
  "il y'a des commandes liés à au site web":
    'existují zakázky související s webovými stránkami',
  Score: 'Skóre',
  'Mot de passe mis à jour avec succès': 'Heslo bylo úspěšně aktualizováno',
  Latin: 'Latinský',
  Technologie: 'Technologie',
  Internet: 'Internet',
  Nauru: 'Nauru',
  'a été créé avec succès': 'byl úspěšně vytvořen',
  Roumain: 'Rumunština',
  Assamais: 'Assamese',
  'Khmer central': 'Střední Khmerové',
  'Navajo ; Navaho': 'Navajo; Navaho',
  'Panjabi ; Punjabi': 'Paňdžábština; paňdžábština',
  'Analyse/Limite': 'Analýza/limit',
  Grec: 'Řecký',
  Ojibwa: 'Ojibwa',
  'Mes Projets': 'Moje projekty',
  'keyword1 (2)\nkeyword2 (1)\nkeyword3 (1)\nkeyword4 (2)\n\u2026':
    'klíčové slovo1 (2)\nklíčové slovo2 (1)\nklíčové slovo3 (1)\nklíčové slovo4 (2)\n...',
  'ajouter un texte': 'přidat text',
  'Détail de la prestation': 'Podrobnosti o službě',
  "Vous avez la possibilité d'exporter le rapport d'une, ou de plusieurs commandes. Pour exporter toutes les commandes d'un client, il vous suffit juste de cliquer sur l'icône de téléchargement en jaune":
    'Máte možnost exportovat sestavu jedné nebo více objednávek. Chcete-li exportovat všechny objednávky zákazníka, stačí kliknout na žlutou ikonu pro stažení.',
  'Gestion des utilisateurs': 'Správa uživatelů',
  'livré et inférieur au nombre de texte non affecté':
    'a méně než počet nepřidělených textů.',
  'Date deadline validation client': 'Lhůta pro ověření zákazníka',
  Aymara: 'Aymara',
  Validées: 'Ověřeno',
  Notes: 'Poznámky',
  'Nombre de GMB': 'Počet GMB',
  'résultats sur': 'výsledky na',
  'Prénom / Nom du client': 'Křestní jméno / Jméno klienta',
  'Tout télécharger': 'Stáhnout vše',
  'Champs obligatoires': 'Povinná pole',
  Éditer: 'Upravit',
  'Coller le texte initial dans le champs ci-dessous':
    'Vložte původní text do pole níže',
  'ID rédacteur': 'ID autora',
  Arménien: 'Arménština',
  'Grande distribution': 'Velkoplošná distribuce',
  Welsh: 'Velština',
  Herero: 'Herero',
  'Enrichissement sémantique': 'Sémantické obohacení',
  'Détail de la prestation du contenu': 'Podrobnosti o poskytování obsahu',
  Noir: 'Černá',
  'Ajouter un texte': 'Přidání textu',
  Traduction: 'Překlad',
  'Immo/Defisc': 'Majetek/daň',
  'Brief détaillé': 'Podrobná stručná informace',
  'Séparez les mots-clés par un Enter .':
    'Klíčová slova oddělte klávesou Enter .',
  Prix: 'Ocenění',
  'mot de passe oublié': 'Zapomenuté heslo',
  'Ndebele, South ; South Ndebele': 'Ndebele, Jižní; Jižní Ndebele',
  'Demander une révision': 'Vyžádat si recenzi',
  'Demander une repasse globale': 'Požádat o celkovou revizi',
  'Sotho, Southern': 'Sotho, jižní',
  'Le titre de la commande existe déjà': 'Název zakázky již existuje',
  'Félicitations !': 'Gratulujeme!',
  Kurde: 'Kurdové',
  Adresse: 'Adresa',
  Espagnol: 'Španělština',
  Ndonga: 'Ndonga',
  'En retard': 'Pozdě',
  'a été ajouté avec succès': 'byl úspěšně přidán',
  Commandes: 'Ovládací prvky',
  'a été mis en brouillon': 'byl vypracován',
  'Rédacteur attribué': 'Přidělený autor',
  Rechercher: 'Vyhledávání',
  'Nouvelle commande': 'Nová objednávka',
  'Commandes non attribuées': 'Nepřidělené zakázky',
  'Le texte a été mis en brouillon': 'Text byl vypracován',
  'Contact edito': 'Kontaktovat edito',
  Langues: 'Jazyky',
  Orange: 'Orange',
  'Nynorsk norvégien': 'Norština Nynorsk',
  Shona: 'Shona',
  Turkmène: 'Turkmen',
  'contenu repassé, en attente validation client':
    'vyžehlený obsah, který čeká na ověření zákazníkem.',
  'Nombre de mots rédigés': 'Počet napsaných slov',
  Malay: 'Malajština',
  'Nombre de Silo': 'Počet sil',
  Oui: 'Ano',
  Automobile: 'Automobilový průmysl',
  Résultats: 'Výsledky',
  Afar: 'Afar',
  'Tâche - id tâche': 'Task - id úlohy',
  'Ce champ est obligatoire doit être supérieur à 0':
    'Toto pole je povinné a musí být větší než 0.',
  'Vous êtes sûr': 'Jste si jistý, že',
  'Réinitialiser votre mot de passe': 'Obnovení hesla',
  Sindhi: 'Sindhi',
  Bengali: 'Bengálština',
  'Bokmål, norvégien ; Bokmål norvégien': 'Bokmål, norština; Norwegian Bokmål',
  'Nombre de Jours': 'Počet dní',
  'Min/Max mots': 'Min/Max slova',
  Slovène: 'Slovinština',
  Site: 'Webové stránky',
  'Sinhala ; Cingalais': 'Sinhálština; sinhálština',
  Bulgare: 'Bulharský',
  'Liste des commandes': 'Seznam objednávek',
  "L'utilisateur": 'Uživatel',
  Uzbek: 'Uzbek',
  'Titre du texte': 'Název textu',
  Postuler: 'Přihlaste se na',
  'Nb de textes par Silo': 'Počet textů na silo',
  'Nombre total de mots (existants et ajoutés)':
    'Celkový počet slov (stávajících a přidaných)',
  'Max.': 'Max.',
  Beauté: 'Krása',
  Humanitaire: 'Humanitární',
  Telugu: 'Telugu',
  'Recherche par rédacteur ou texte': 'Hledat podle autora nebo textu',
  'Balise meta-description': 'Meta description tag',
  Malgache: 'Madagaskar',
  Tagalog: 'Tagalog',
  'Ce champs doit être sup à 0 et inférieur à 1':
    'Toto pole musí být větší než 0 a menší než 1.',
  'Le rédacteur': 'Redaktor',
  'a été mis à jour avec succès': 'byl úspěšně aktualizován',
  'Avoir au moins 8 caractères avec une lettre majuscule, un chiffre, un symbole et ne pas être identique au nom.':
    'Musí obsahovat alespoň 8 znaků, velké písmeno, číslici, symbol a nesmí být totožný se jménem.',
  'textes en plus': 'další texty',
  'Format souhaité': 'Požadovaný formát',
  Chamorro: 'Chamorro',
  Malayalam: 'Malayalam',
  'Détail ligne de commande': 'Detail řádku objednávky',
  'Veuillez préciser la date': 'Uveďte prosím datum',
  Pali: 'Pali',
  Clients: 'Zákazníci',
  'Total facturé': 'Fakturováno celkem',
  'contenu validé par le client': 'obsah ověřený klientem',
  'Occitan (post 1500)': 'okcitánština (po roce 1500)',
  Japonais: 'Japonský',
  Avaric: 'Avaric',
  'Voulez-vous vraiment activer': 'Opravdu chcete aktivovat',
  Sanskrit: 'Sanskrt',
  'Toutes les notifications': 'Všechna oznámení',
  Marathi: 'Maráthština',
  Persan: 'Perský',
  'La commande pour le client': 'Objednávka pro zákazníka',
  'Intitulé de la commande': 'Název zakázky',
  Samoan: 'Samoan',
  Autres: 'Další',
  Sport: 'Sport',
  "il y'a des commandes liés au site web ":
    'existují zakázky související s webovými stránkami',
  'Ajout de mots-clé avec la fréquence restante':
    'Přidání klíčových slov se zbývající frekvencí',
  'a été supprimé avec succès': 'byl úspěšně odstraněn',
  Postulé: 'Posted',
  Literie: 'Povlečení',
  'Édité par le client': 'Upraveno klientem',
  Cornouailles: 'Cornwall',
  Tigrinya: 'Tigrinya',
  Textes: 'Texty',
  'Soumettre un nouveau texte': 'Odeslání nového textu',
  Téléphone: 'Telefon',
  Deadline: 'Termín',
  "Une erreur s'est produite. Veuillez vérifier votre saisie ou essayer plus tard.":
    'Došlo k chybě. Zkontrolujte prosím zadání nebo to zkuste později.',
  Marshallais: 'Marshall',
  "La demande d'ajout de crédit est envoyée":
    'Žádost o připsání kreditu je odeslána',
  'Le rédacteur ': 'Redaktor',
  'Demander des crédits': 'Žádost o úvěr',
  "Cette commande n'existe pas": 'Tento příkaz neexistuje',
  Inupiaq: 'Inupiaq',
  Tahitien: 'Tahitian',
  Admins: 'Administrátoři',
  'Mettez des mots-clés avec fréquence': 'Nastavení klíčových slov s frekvencí',
  Notifications: 'Oznámení',
  'en retard': 'pozdě',
  "l'admin": 'správce',
  'Mail rédacteurs': 'autoři pošty',
  Amharique: 'Amharština',
  Societe: 'Společnost',
  Tsonga: 'Tsonga',
  'Nombre de textes ajoutés': 'Počet přidaných textů',
  Société: 'Společnost',
  Yoruba: 'Yoruba',
  'Le texte a été livré avec succès': 'Text byl úspěšně doručen',
  'Mettez votre texte': 'Vložte svůj text',
  "Oups....! Une erreur s'est produite": 'Oops....! Došlo k chybě',
  'Prénom/Nom': 'Jméno/příjmení',
  'Max doit être sup à Min': 'Max musí být větší než Min',
  'Importer un autre document': 'Import jiného dokumentu',
  'Votre demande a expirée .. veuillez refaire une autre':
    'Platnost vaší žádosti vypršela... vytvořte prosím novou.',
  'Veuillez corriger le format de votre gap sémantique afin de pouvoir sauvegarder le texte en brouillon':
    'Opravte prosím formát sémantické mezery, abyste mohli text uložit jako návrh.',
  Exemple: 'Příklad',
  Slovaque: 'Slovenská',
  Finance: 'Finance',
  Réactiver: 'Znovu aktivovat',
  'Nb textes': 'Počet textů',
  'Intitulé commande': 'Název objednávky',
  Supprimer: 'Odstranit',
  Brouillon: 'Návrh',
  'Mots clés restants': 'Zbývající klíčová slova',
  Date: 'Datum',
  Rôle: 'Role',
  'Ce champs doit être sup à 0': 'Toto pole musí být větší než 0',
  'Maison/Déco': 'Domov/Dekor',
  'Vous avez': 'Máte',
  'Cette ligne de commande ne peut pas être supprimée car une partie de ses textes a été livré':
    'Tento příkazový řádek nelze odstranit, protože část jeho textu byla doručena.',
  'vérifiez votre email et cliquez sur le lien pour continuer !':
    'zkontrolujte svůj e-mail a klikněte na odkaz pro pokračování!',
  Sarde: 'Sardinie',
  'Note rédacteur': 'Poznámka redakce',
  'Uighur ; Uyghur': 'Ujgurština; Ujgurština',
  Name: 'Název',
  'Commandes intelligentes': 'Smart příkazy',
  'Commandes avec révision': ' Smart + review příkazy',
  'Type de contenu': 'Typ obsahu',
  'Fiche produit': 'Produktový list',
  GMB: 'GMB',
  'STORE LOCATOR': 'STORE LOCATOR',
  'Rédaction premium': 'Premium redakce',
  'Rédaction avec révision': 'Smart + review redakce',
  'Rédaction intelligente': 'Smart redakce',
  PREMIUM: 'PREMIUM',
  Type: 'Typ',
  Editer: 'Upravit',
  'Tous les types': 'Všechny typy',
  Smart: 'Smart',
  'Smart + review': 'Smart + review',

  'Factures traitées': 'Zpracované faktury',
  'Factures à valider': 'Faktury k validaci',
  'Impossible de générer la facture ! Veuillez réessayer':
    'Generování faktury se nezdařilo! Prosím, zkuste to znovu',
  'Récapitulatif de facturation': 'Souhrn fakturace',
  'Demander une modification': 'Požádat o úpravu',
  'Valider et envoyer la facture': 'Potvrdit a odeslat fakturu',
  'Demande de modification': 'Žádost o úpravu',
  'La facture a été validé avec succès!': 'Faktura byla úspěšně potvrzena!',
  "Oups un erreur c'est produit!": 'Ups, došlo k chybě!',
  'Nombre de texte': 'Počet textů',
  'Nombre de mot par texte :': 'Počet slov na textu:',
  Paiement: 'Platba',
  Virement: 'Převod',
  Délai: 'Termín',
  'Référence de votre facture': 'Odkaz na vaši fakturu',
  'Coût au mot': 'Cena za slovo',
  'Montant global de la commande': 'Celková částka objednávky',
  'Votre facture': 'Vaše faktura',
  'Importer votre facture': 'Importovat vaši fakturu',
  'Pas de fichier disponible': 'Žádný dostupný soubor',
  Télécharger: 'Stáhnout',
  Relancer: 'Opakované zaslání',
  'Générer la facture': 'Vygenerovat fakturu',
  Payée: 'Zaplaceno',
  'En cours de paiement': 'Probíhá platba',
  'En cours de validation': 'Probíhá validace',
  'En cours de modification': 'V procesu úpravy',
  'A envoyer': 'K odeslání',
  'Modification demandée': 'Požadována úprava',
  'Référence de facture est obligatoire': 'Odkaz na fakturu je povinný',
  'Le coût au mot doit être un nombre': 'Cena za slovo musí být číslo',
  'Le coût au mot doit être un nombre positif':
    'Cena za slovo musí být kladné číslo',
  'Le coût au mot est obligatoire': 'Cena za slovo je povinná',
  'Le montant global de la commande doit être un nombre':
    'Celková částka objednávky musí být číslo',
  'Le montant global de la commande doit être un nombre positif':
    'Celková částka objednávky musí být kladné číslo',
  'Le montant global de la commande est obligatoire':
    'Celková částka objednávky je povinná',
  'Bill reference existe déja , veuillez réessayer':
    'Odkaz na fakturu již existuje, prosím, zkuste to znovu',
  'La facture a été envoyée avec succès!': 'Faktura byla úspěšně odeslána!',

  'Montant estimé': 'Odhadovaná částka',
  Action: 'Akce',
  Facture: 'Faktura',
  Statut: 'Stav',
  Factures: 'Faktury',
  'Valider la facture': 'Ověřit fakturu',
  'La relance a été envoyée avec succès !': 'Výzva byla úspěšně odeslána!',
  'La demande de modification a été envoyée avec succès !':
    'Žádost o úpravu byla úspěšně odeslána!',
  'Le cout est trop élevé': 'Cena je příliš vysoká',
  'Le montant global de la commande est trop élevé ':
    'Celková částka objednávky je příliš vysoká',
  jours: 'dny',
  'Factures à envoyer': 'Faktury k odeslání',
  'Valider le paiment': 'Potvrdit platbu',
  'Générer une facture': 'Generovat fakturu',
  'Montant rédacteur': 'Částka pro redaktora',
  'Montant estimé par Typix': 'Odhadovaná částka Typixem',
  'Voir la modification': 'Zobrazit úpravu',

  'Tag de Titre': 'Titulkový tag',
  'Meta description': 'Meta popis',
  'Nom du produit': 'Název produktu',
  Avantages: 'Výhody',
  'Description détaillée': 'Podrobný popis',
  'Mes Factures': 'Moje faktury',
  'En attente de validation': 'Čeká na schválení',
  'les valeurs négatives ne sont pas autorisées':
    'Negativní hodnoty nejsou povoleny.',
  'Texte Seulement': 'Pouze text',
  'Traduction Seulement': 'Pouze překlad',
  'La relance peut être faite 20 jours après la soumissions de la facture':
    'Připomínka může být zaslána 20 dní po odeslání faktury',
  'Verifier la facture': 'Zkontrolujte fakturu',
  Instructions: 'Instrukce',
  'Votre avis pour la commande': 'Váš názor na objednávku',
  'Votre évaluation est précieuse pour nous ! Merci !':
    'Vaše hodnocení je pro nás cenné! Děkujeme!',
  "Une erreur s'est produite lors de la soumission de votre évaluation. Veuillez réessayer ultérieurement!":
    'Při odesílání vašeho hodnocení došlo k chybě. Prosím, zkuste to později!',
  Evaluer: 'Hodnotit',
  Commentaire: 'Komentář',
  'Votre commantaire': 'Váš komentář',
  Note: 'Hodnocení',
  'Votre retour est précieux pour nous et nous aide à améliorer nos services.':
    'Vaše zpětná vazba je pro nás cenná a pomáhá nám vylepšovat naše služby.',

  ' Votre retour est précieux pour nous et nous aide à améliorer nos services. Merci de partager votre évaluation avec nous!':
    'Vaše zpětná vazba je pro nás cenná a pomáhá nám zlepšovat naše služby. Děkujeme, že ji sdílíte s námi!',
  'Notes rédacteur': 'Poznámky autora',
  'Avis client': 'Hodnocení zákazníka',
  'Pas de commantaire': 'Žádný komentář',
  "Qu'avez vous pensé de votre commande?": 'Co si myslíte o vaší objednávce?',
  'Evaluation de la commande': 'Hodnocení objednávky',
  'Rédiger un commentaire': 'Napsat komentář',
  'Contribuez à nous permettre de vous proposer les services de rédaction de la plus haute qualité en partageant vos commentaires.':
    'Pomozte nám nabízet vám služby psaní nejvyšší kvality tím, že sdílíte své komentáře.',
  'Que pouvons-nous améliorer?': 'Co můžeme zlepšit?',
  Continuer: 'Pokračovat',
  'Le dernier texte a été validé avec succès':
    'Poslední text byl úspěšně ověřen',
  'Rechercher par rédacteur, commandes, et montant':
    'Vyhledávat podle redaktora, objednávek a částky',
  obligatoire: 'povinný',
  'Note admin': 'Poznámka administrátora',
  'Note client': 'Poznámka klienta',
  Partie: 'Část',
  'Vous venez de valider tous les contenus rédigés par ':
    'Právě jste schválil všechen obsah napsaný ',
  "Qu'en avez vous pensé": 'Co jste si o tom mysleli',
  'Lafacture a été générée avec succès !': 'Faktura byla úspěšně vygenerována!',
  'La taille du titre et/ou la méta-description est trop courte':
    'Délka nadpisu a/nebo meta popisu je příliš krátká',
  'Demande de révision globale': 'Vyžadovat celkovou revizi',
  Valider: 'Ověřit',
  'Obligatoire en cas d’optimisation ou traduction':
    'Povinné v případě optimalizace nebo překladu',
  'Mots clés Bourrées': 'Keyword stuffing',
  'Votre commentaire...': 'Váš komentář...',
  'Ex : "Contenus de très bonne qualité': 'Např.: "Velmi kvalitní obsah"',
  "pouvez-vous s'il vous plaît reprendre ce/tte rédacteur/rice pour les prochaines commandes?":
    'Můžete, prosím, tohoto autora přidělit pro budoucí objednávky?',
  'Ce bouton est pour informer le client que le texte initial, contenait déjà du keyword stuffing':
    'Toto tlačítko slouží k informování klienta, že původní text již obsahoval nadměrné použití klíčových slov',
  'Maillage Interne': 'Internal link',
  'Modifier le texte': 'Upravit text',
  Ajouter: 'Přidat',
  'Demande examen': 'Žádost o zkoušku',
  'Recherche de mots clés à haute densité': 'Hledání keyword stuffing',
  'Oups, il semblerait qu’il y ait du keyword stuffing dans votre texte : ':
    'Oops, zdá se, že ve vašem textu je keyword stuffing: ',
  'Oups, il semblerait qu’il y ait du keyword stuffing (trop d’occurrences d’un ou plusieurs mots clés) dans le texte initial.':
    'Oops, zdá se, že v původním textu je keyword stuffing (příliš mnoho výskytů jednoho nebo více klíčových slov).',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).':
    'Prosím, ujistěte se, že hustota klíčových slov je optimalizována pro SEO (maximálně jedna výskyt každých 50 slov).',
  'Pour cela merci de ne pas retoucher le texte existant et de bien vouloir envoyer une demande d’examen au support.':
    'Pro toto, prosím neupravujte stávající text a laskavě pošlete žádost o zkoušku na podporu.',
  'Votre demande a été bien envoyée !': 'Vaše žádost byla úspěšně odeslána!',
  "Il y a eu un problème lors de l'envoi de votre demande":
    'Při odesílání vašeho požadavku nastal problém.',
  'Veuillez attendez pour le  keyword Stuffing analyse':
    'Čekejte prosím na analýzu keyword stuffing.',
  'Inserez le maillage interne': 'Vložte interní propojení',
  'Merci de vous assurer que la densité des mots clés soit optimisée pour le SEO (une occurrence maximum tous les 50 mots).\nSi vous constatez un terme pouvant avoir plusieurs significations (Exemple : Former / Formation/ Forme ou Matelas/Matelassé) , vous pouvez faire une demande d’examen auprès du support.':
    'Ujistěte se, že hustota klíčových slov je optimalizována pro SEO (maximálně jedna výskyt každých 50 slov).\nPokud narazíte na termín, který může mít více významů (Příklad: Forma / Formace / Tvar nebo Matrace / Vyšívaná), můžete požádat o přezkum u podpory.',
  'Mots clés à haute densité dans le texte actuel':
    'Klíčová slova s vysokou hustotou v aktuálním textu',
  'Mots clés à haute densité dans le texte initial':
    'Klíčová slova s vysokou hustotou v původním textu',
  'Texte en cours de rédaction !': 'Text se píše !',
  'Êtes-vous sûr(e) de vouloir valider le texte ? Assurez-vous d’abord d’avoir enregistré les modifications.':
    'Jste si jistý/á, že chcete potvrdit text? Nejprve se ujistěte, že jste uložil(a) změny.',
  "Information dernière minute ! Ton stock de crédits sera bientôt épuisé :( Pour anticiper, n'hésite pas à demander dès maintenant plus de crédits pour terminer ta commande.":
    'Poslední informace! Tvůj kreditní zásobník brzy dojde :( Pro předběžnou přípravu neváhej teď požádat o více kreditů k dokončení objednávky.',
  'Voulez-vous confirmer l’URL actuelle ou la modifier ?':
    'Chcete potvrdit aktuální URL nebo ji změnit?',
  'Veuillez fournir une URL valide': 'Prosím, zadejte platnou URL adresu.',
  'Merci de bien vouloir indiquer ci-dessous l’url de la page où a été intégré le contenu:':
    'Děkujeme, že uvedete níže URL adresu stránky, kde byl obsah integrován:',
  'Contenu en ligne': 'Obsah online',
  'Télécharger le document de révision': 'Stáhnout revizní dokument',
  'Date d’envoi': 'Datum odeslání',
  Information: 'Informace',
  Enregistrer: 'Uložit',
  'Textes en ligne': 'Texty online,',
  'Livrer les textes': 'Doručit texty',
  Indisponible: 'Nedostupné',
  Disponible: 'Dostupné',
  'Les textes ont été livrés avec succès.': 'Texty byly úspěšně doručeny.',
  'Le texte a été livré avec succès.': 'Text byl úspěšně doručen.',
  'Les textes ont été validés avec succès.': 'Texty byly úspěšně ověřeny.',
  'Le texte a été validé avec succès.': 'Text byl úspěšně ověřen.',
  'Voulez-vous vraiment valider les Textes de la commande':
    'Opravdu chcete ověřit texty objednávky?',
  'Voulez-vous vraiment livrer les Textes de la commande':
    'Opravdu chcete doručit texty objednávky?',
  'Fiche produit longue': 'Dlouhý produktový list',
  'Fiche produit courte': 'Krátký produktový list',
  'Un texte est trop long et ne peut pas être exporté sur Excel':
    'Text je příliš dlouhý a nelze jej exportovat do Excelu.',
};
