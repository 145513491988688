export const navigationPaths = [
  {
    id: 'ADMIN',
    pagePaths: {
      addClient: '/Admin/AjouterClient',
      newOrder: '/Admin/NouvelleCommande',
      userProfile: '/Admin/MonProfil',
      mainMenu: '/Admin/MainMenu',
      nonAttributedOrders: '/Admin/CommandesNonAttribuees',
      pendingOrders: '/Admin/CommandesEnCours',
      lateOrders: '/Admin/CommandesEnRetard',
      newOrders: '/Admin/NouvelleCommande',
      validatedOrders: '/Admin/CommandesValides',
      smartOrders: '/Admin/CommandesIntelligentes',
      smartWithRviewOrders: '/Admin/CommandesReview',
      writers: '/Admin/Redacteur',
      userManager: '/Admin/GestionUtilisateur',
    },
  },
  {
    id: 'WRITER',
    pagePaths: {
      userProfile: '/Redacteur/MonProfile',
      mainMenu: '/Redacteur/MainMenu',
      nonAttributedOrders: '/Redacteur/CommandesNonAttribuée',
      pendingOrders: '/Redacteur/CommandesEnCours',
      lateOrders: '/Redacteur/CommandesEnRetard',
      validatedOrders: '/Redacteur/CommandesValides',
      facturation: '/Redacteur/MesFactures',
      smartWithRviewOrders: '/Redacteur/CommandesReview',
      analyzer: '/Redacteur/Analyzer',
    },
  },
  {
    id: 'CLIENT',
    pagePaths: {
      userProfile: '/Client/MonProfile',
      mainMenu: '/Client/MainMenu',
      notification: '/Client/Notification',
      REVISION_DONE: 'REVISION_DONE',
      revisionDoneTexts: 'Clients/texteRévisés',
      deliveredTexts: 'Clients/texteLivrés',
      readyOrders: 'Client/commandesPrête',
    },
  },
  {
    id: 'CONSULTANT',
    pagePaths: {
      userProfile: '/Consultant/MonProfile',
      mainMenu: '/Consultant/MainMenu',
    },
  },
];

export const getPathsByUserId = (id) =>
  navigationPaths.find((user) => user.id === id);
